
module.exports = function (APP, $, bootstrap, Fancybox) {
	var module = {};
	
	const Cropper = require('cropperjs');
	require('@fancyapps/ui/dist/fancybox.esm');
	const inst = APP.rawImageUpload = {
		
		getWidget: function(options = {}) {
			options = {...inst.getDefaultData(), ...options};
			
			// HTML
			const $widget = $(`<div class="raw-image-upload-widget d-flex">
				<div class="flex-0" style="flex-basis: 200px;">
					<button type="button" class="image-btn empty-image" data-action="multiButton"></button>
				</div>
				<div class="ms-3">
					<label class="form-label d-block">${options.label}</label>
					<button type="button" class="if-src btn btn-sm btn-light me-2 mb-2" data-action="remove">
						<i class="fas fa-trash"></i>
						Supprimer
					</button>
					<button type="button" class="if-prev-src btn btn-sm btn-light me-2 mb-2" data-action="restore">
						<i class="fas fa-undo"></i>
						Restaurer
					</button>
					<button type="button" class="btn btn-sm btn-light me-2 mb-2" data-action="addFile">
						<i class="fas fa-upload"></i>
						Envoyer...
					</button>
					<button type="button" class="btn btn-sm btn-light me-2 mb-2" data-action="browse">
						<i class="fas fa-folder"></i>
						Parcourir...
					</button>
					<input type="file" accept="image/*">
					<canvas></canvas>
				</div>
			</div>`);
			
			// Init data container
			const widget = inst.setData($widget, options);
			
			// Interactions
			$widget.on('click', '[data-action="addFile"]', function() {
				inst.addFile($widget);
			});
			$widget.on('click', '[data-action="remove"]', function() {
				inst.setData($widget, {
					prevSrc: inst.getData($widget, 'src'),
					src: null
				});
				inst.updateWidget($widget);
				widget.rawImageUpload.onChange(inst.getData($widget));
			});
			$widget.on('click', '[data-action="restore"]', function() {
				inst.setData($widget, {
					src: inst.getData($widget, 'prevSrc'),
					prevSrc: null
				});
				inst.updateWidget($widget);
				widget.rawImageUpload.onChange(inst.getData($widget));
			});
			$widget.on('click', '[data-action="multiButton"]', function() {
				if(!inst.getData($widget, 'src'))
					inst.addFile($widget);
				else
					inst.lightbox($widget);
			});
			$widget.on('click', '[data-action="browse"]', function() {
				inst.browse($widget);
			});
			$widget.on('change', 'input[type="file"]', function() {
				let files = this.files;
				inst.onSelect($widget, files)
			});
			
			// Update
			inst.updateWidget($widget);
			
			return $widget;
		},
		
		browse: function($widget) {
			const widget = $widget.get(0);
			const fancybox = new Fancybox([{
				src: '/files/browse',
				type: "iframe",
				preload: false,
			}], {
				on: {
					done: function() {
						const iframe = $('.fancybox__iframe').get(0);
						
						iframe.onload = function() {
							const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
							
							// @see files.js
							iframeDocument.onFileSelect = iframeDocument.onFileUpload = function(file) {
								
								// Select image
								inst.setData($widget, {
									src: file.paths[inst.getData($widget, 'fileVersion')],
								});
								inst.updateWidget($widget);
								widget.rawImageUpload.onChange(inst.getData($widget));
								fancybox.close();
							};
						}
					}
				}
			});
		},
		
		lightbox: function($widget) {
			const fancybox = new Fancybox([
				{
					src: inst.getData($widget, 'src'),
					// type: "html",
				},
			]);
		},
		
		getModal: function($widget) {
			return $(`<div class="modal raw-image-upload-modal" tabindex="-1" data-bs-backdrop="static">
				  <div class="modal-dialog modal-xl">
				    <div class="modal-content">
				      <div class="modal-body">
						  <div class="raw-image-upload-spinner">
							<i class="fas fa-spinner fa-pulse"></i>
						  </div>
						  <div class="raw-image-upload-preview d-none">
						    <div>
						        <img class="raw-image-upload-preview-image"/>
						    </div>
						    <div>
								<div class="d-grid gap-2">
									<div class="btn-group" role="group">
									  <button type="button" class="btn btn-sm btn-primary" data-action="rotate" data-angle="-90"><i class="fas fa-undo"></i></button>
									  <button type="button" class="btn btn-sm btn-primary" data-action="rotate" data-angle="90"><i class="fas fa-redo"></i></button>
									  <button type="button" class="btn btn-sm btn-primary" data-action="zoom" data-ratio="-.1"><i class="fas fa-search-minus"></i></button>
									  <button type="button" class="btn btn-sm btn-primary" data-action="zoom" data-ratio=".1"><i class="fas fa-search-plus"></i></button>
									</div>
									<button type="button" data-action="confirm" class="btn btn-primary">
										<i class="fas fa-check"></i> Valider
									</button>
									<button type="button" data-action="cancel" class="btn btn-light">
										<i class="fas fa-times"></i> Annuler
									</button>
								</div>
						    </div>
						  </div>
				      </div>
				    </div>
				  </div>
				</div>`);
		},
		
		getDefaultData: function($widget = null) {
			let defaultData = {
				label: "Image",
				src: null,
				fileVersion: 'md',
				prevSrc: null,
				file: null,
				maxWidth: 2500,
				maxHeight: 2000,
				attributes: {},
				onUpdate: function(){},
				onChange: function(){},
			};
			if($widget)
				defaultData = {...defaultData, ...{
					$ifSrc: $widget.find('.if-src'),
					$ifNotSrc: $widget.find('.if-not-src'),
					$ifPrevSrc: $widget.find('.if-prev-src'),
					$ifNotPrevSrc: $widget.find('.if-not-prev-src'),
					canvas: $widget.find('canvas').get(0),
				}};
			return defaultData;
		},
		
		setData: function($widget, options) {
			const defaults = inst.getDefaultData($widget);
			
			if(typeof $widget.get(0).rawImageUpload === "undefined")
				$widget.get(0).rawImageUpload = defaults;
			
			const prevConfig = $widget.get(0).rawImageUpload;
			const widget = $widget.get(0);
			widget.rawImageUpload = {...defaults, ...prevConfig, ...options};
			
			return widget;
		},
		
		getData($widget, prop = null) {
			const config = $widget.get(0).rawImageUpload;
			
			if(typeof config === "undefined")
				return prop ? null : {};
			
			if(prop)
				return typeof config[prop] !== "undefined"
					? config[prop]
					: null;
			
			return config;
		},
		
		addFile: function($widget) {
			$widget.find('input[type=file]').click();
		},
		
		updateWidget: function($widget) {
			const widget = $widget.get(0);
			const src = inst.getData($widget, 'src');
			const prevSrc = inst.getData($widget, 'prevSrc');
			
			widget.rawImageUpload.$ifNotSrc.toggle(null === src);
			widget.rawImageUpload.$ifSrc.toggle(null !== src);
			widget.rawImageUpload.$ifNotPrevSrc.toggle(null === prevSrc);
			widget.rawImageUpload.$ifPrevSrc.toggle(null !== prevSrc);
			
			// Update preview button
			$widget.find('.image-btn').toggleClass('empty-image', null === src)
				.css('background-image', src ? 'url("'+src+'")' : null)
			;
			
			// Callback
			widget.rawImageUpload.onUpdate();
		},
		
		onSelect: function($widget, files) {
			const widget = $widget.get(0);

			Array.from(files).forEach((file, i) => {
				// this file is not an image.
				if (!file.type.match(/image.*/)){
					inst.error("File "+(i+1)+" is not a valid image file: \n"+file.name, true);
					$widget.blobCount--;
				}

				// Image ok...
				else{
					// Save name
					inst.setData($widget, {
						file: file
					});
					
					// Open modale
					const $modal = inst.getModal($widget);
					const bsModal = new bootstrap.Modal($modal, {})
					
					// Remove on close
					$modal.get(0).addEventListener('hidden.bs.modal', function (event) {
						$(this).remove();
					});
					// Handle close
					$modal.on('click', '[data-action="cancel"]', function() {
						bsModal.hide();
					});
					// Open
					bsModal.show();
					
					// Canvas
					const canvas = inst.getData($widget, 'canvas');
					
					// Une fois l'image en mémoire vive
					let img = document.createElement("img");
					$(img).data('filename', file.name).on('load', function() {
						let img = this;
						const maxWidth = inst.getData($widget, 'maxWidth');
						const maxHeight = inst.getData($widget, 'maxHeight');

						// Resize to max
						let width = img.width;
						let height = img.height;
						if (width > height) {
							if (width > maxWidth) {
								height *= maxWidth / width;
								width = maxWidth;
							}
						} else {
							if (height > maxHeight) {
								width *= maxHeight / height;
								height = maxHeight;
							}
						}
						canvas.width = width;
						canvas.height = height;

						// Draw
						let ctx = canvas.getContext("2d");
						ctx.drawImage(img, 0, 0, width, height);

						// Resized image to blob
						canvas.toBlob(function(blobImage) {
							const reader = new FileReader();
							const $previewImage = $modal.find('.raw-image-upload-preview-image');
							reader.readAsDataURL(blobImage);
							reader.onloadend = function() {
								
								// Preview image
								inst.toggleModalStandby($modal, false);
								$previewImage.attr('src', reader.result);
								
								// Cropper
								const cropper = new Cropper($previewImage.get(0), {
									viewMode: 2,
									autoCropArea: 1,
								});
								$modal.on('click', '[data-action="rotate"]', function() {
									cropper.rotate($(this).data('angle'));
								});
								$modal.on('click', '[data-action="zoom"]', function() {
									cropper.zoom($(this).data('ratio'));
								});
								
								// Submit
								$modal.on('click', '[data-action="confirm"]', function() {
									const base64Image = cropper.getCroppedCanvas().toDataURL();
									inst.toggleModalStandby($modal, true);
									
									// Post image to back
									inst.post($widget, base64Image, function(result) {
										// on success
										bsModal.hide();
										inst.setData($widget, {
											src: result.paths[inst.getData($widget, 'fileVersion')]
										});
										inst.updateWidget($widget);
										widget.rawImageUpload.onChange(inst.getData($widget));
										
									}, function(result) {
										// on done
										inst.toggleModalStandby($modal, false);
									});
								});
								
							}

						}, file.type, 0.8);
					});

					// Charger l'image
					img.src = window.URL.createObjectURL(file);
				}
			});
		},
		
		toggleModalStandby: function($modal, toggle) {
			$modal.find('.raw-image-upload-spinner').toggleClass('d-none', !toggle);
			$modal.find('.raw-image-upload-preview').toggleClass('d-none', toggle);
		},
		
		base64ToBlob: function(base64, mime) {
			mime = mime || '';
			var sliceSize = 1024;
			var byteChars = window.atob(base64);
			var byteArrays = [];
			
			for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
				var slice = byteChars.slice(offset, offset + sliceSize);
				
				var byteNumbers = new Array(slice.length);
				for (var i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				
				var byteArray = new Uint8Array(byteNumbers);
				
				byteArrays.push(byteArray);
			}
			
			return new Blob(byteArrays, {type: mime});
		},
		
		post: function($widget, base64Image, onSuccess, onDone) {
			const widget = $widget.get(0);
			const file = inst.getData($widget, 'file');
			const url = "/api/upload-file";
			const base64ImageContent = base64Image.replace(/^data:image\/(png|jpg);base64,/, "");
			const blob = inst.base64ToBlob(base64ImageContent, file.type);
			
			let formData = new FormData();
			formData.append('files[]', blob, file.name);
			
			$.ajax({
				url: url,
				type: "POST",
				cache: false,
				contentType: false,
				processData: false,
				data: formData,
				
				success: function(result){
					// back-end error
					if(typeof result.error !== "undefined" || result.error) {
						inst.error(result.error, true);
					}
					// Success
					else{
						onSuccess(result);
					}
					onDone(result);
				},
				// front-end error
				error: function(result) {
					console.log('Ajax result:', result);
					inst.error("Ajax error, check log", true);
					onDone(result);
				},
			});
		},
		
		// Something wrong
		error: function(message, doAlert){
			if("undefined" !== typeof doAlert)
				alert(message);
			console.error("Upload error: ", message);
		},
	};
	
	
	// Init on load
	// $(document).ready(function() {
		// inst.init();
	// });
	
	return module;
};
