const throttle = require("lodash/throttle");
const debounce = require("lodash/debounce");
/**
 * Utiliser document.onFileSelect() pour des ajouter des méthodes externes à la sélection
 * Utiliser document.onFileUpload() pour des ajouter des méthodes externes à la sélection
 */
module.exports = function (APP, $, Fancybox) {
	const throttle = require('lodash/throttle');
	const debounce = require('lodash/debounce');
	
	const inst = APP.files = {
		
		init: function(){
			inst.$fileBrowsers = $('.file-browser');
			
			inst.$fileBrowsers.each(function() {
				inst.initFileBrowser(this);
			})
			
			$('.mini-file-upload').each(function(){
				inst.initMiniFileUpload(this);
			});
		},
		
		initFileBrowser: function(browser) {
			const $fileBrowser = $(browser);

			// Properties
			browser.files = {
				p: 0,
				q: $fileBrowser.find('.search-input').val(),
			};
			
			// Select
			$fileBrowser.on('click', '[data-action="selectItem"]', function() {
				const $item = $(this).parentsUntil('.bin').last();
				inst.selectItem($item.data('metas'));
			});
			
			// Infinite scroll btn
			$fileBrowser.on('click', '[data-action="loadMore"]', function() {
				inst.loadBin(browser);
			});
			
			// Search
			$fileBrowser.on('change click keyup', '.search-input', debounce(function() {
				// Ignore unchanged
				if(""+browser.files.q === ""+$(this).val())
					return;
				inst.clearBin(browser);
				browser.files.q = $(this).val();
				inst.loadBin(browser);
			}, 600, {
				leading: false,
			}));
			
			// Config
			$fileBrowser.on('change', 'select', function() {
				inst.clearBin(browser);
				inst.loadBin(browser);
			});
			
			// Load first page
			inst.loadBin(browser);
		},
		
		clearBin: function(browser) {
			$(browser).find('.bin').html('');
			browser.files.p = 0;
		},
		
		selectItem: function(file) {
			
			// Trigger outside methods
			if(typeof document.onFileSelect === "function")
				return document.onFileSelect(file);
			if(typeof parent.document.onFileSelect === "function")
				return parent.document.onFileSelect(file);
		},
		
		loadBin: function(browser) {
			const $browser = $(browser);
			const $bin = $browser.find('.bin').first();
			const $loadingBar = $browser.find('.file-loading').first();
			const $loadMoreBtn = $browser.find('[data-action="loadMore"]');
			const order = $browser.find('[name="order"]').val();
			const dir = $browser.find('[name="dir"]').val();
			
			$loadingBar.addClass('visible');
			$.ajax({
				url: '/api/files/browse',
				cache: false,
				data: {
					p: browser.files.p,
					q: browser.files.q,
					order: order,
					dir: dir,
				},
				success: function(result){
					// Back-end error
					if(typeof result.files === "undefined")
						console.error('Ajax result:', result);
					
					// Success
					else{
						// Spawn list
						result.files.forEach(function(file) {
							$bin.append($(file.htmlItem));
						});
						
						// More to go ? Toggle load btn
						let more = result.count > $bin.children().length;
						$loadMoreBtn.toggle(more);
						
						// Increment page
						browser.files.p++;
					}
				},
				error: function(result) {
					console.error('Ajax result:', result);
				},
				complete: function() {
					$loadingBar.removeClass('visible');
				}
			});
		},
		
		openUpdateModal(file) {
			const fancybox = new Fancybox([{
				src: '/files/'+file.id+'/update',
				type: "iframe",
				preload: false,
			}], {
				on: {
					done: function() {
						const iframe = $('.fancybox__iframe').get(0);
						iframe.onload = function() {
							const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
						}
					}
				}
			});
		},
		
		// Something wrong
		error: function(message, doAlert){
			if("undefined" !== typeof doAlert)
				alert(message);
			console.error("Upload error: ", message);
		},
		
		// ////
		// FILE UPLOAD
		
		initMiniFileUpload: function(elt) {
			const $elt = $(elt);
			const $button = $elt.find('button');
			const $input = $elt.find('[type=file]');
			const input = $input.get(0);
			
			$elt.find('button').on('click', function() {
				$input.trigger('click');
			});
			
			$input.on('change', function() {
				let formData = new FormData();
				formData.append("files[]", input.files[0]);
				const btnClasses = 'disabled btn-striped animated-stripes';
				$button.addClass(btnClasses);
				
				$.ajax({
					url: '/api/upload-file',
					type: "POST",
					cache: false,
					contentType: false,
					processData: false,
					data: formData,
					
					success: function(result){
						// back-end error
						if(typeof result.error !== "undefined" || result.error) {
							inst.error(result.error, true);
						}
						// Success
						else{
							// Specific method ?
							if(typeof document.onFileUpload === "function")
								document.onFileUpload(result.file);
							// Default
							else
								document.location.reload();
						}
					},
					// front-end error
					error: function(result) {
						console.log('Ajax result:', result);
						inst.error("Ajax error, check log", true);
					},
					complete: function() {
						$button.removeClass(btnClasses);
					}
				});
			});
		},
		
	};
	
	// Init on load
	$(document).ready(function() {
		inst.init();
	});
	
	return module;
}
