
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					slug: $elt.find('[data-name="slug"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {
				slug: null,
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3">' +
				'<i class="fas fa-info-circle"></i> ' +
				'Une ancre permet de créer des liens vers une partie donnée de la page via un identifiant unique : <br>' +
				'Cet identifiant apparaît après le signe <i class="fas fa-hashtag"></i> dans l\'URL de la page. ' +
				'</p>')
			
			// slug container
			const $slugWrapper = $('<div class="input-group mb-2"><span class="input-group-text" ><i class="fas fa-hashtag"></i></span></div>');
			
			// slug
			$(`<input class="form-control" data-name="slug" placeholder="mon_identifiant_unique">`)
				.val(config.slug)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.appendTo($slugWrapper)
			;
			$tpl.append($slugWrapper);
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('anchor', inst);
};
