
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Load existing slides
			if(data.hasOwnProperty('slides')) {
				data.slides.reverse().forEach(slide => {
					inst.addSlide(blockElt, scene, {
						autofocus: false,
						title: slide.title,
						description: slide.description,
						image: slide.image,
					});
				});
			}
			else {
				// Empty slide
				inst.addSlide(blockElt, scene, {autofocus: false});
			}
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			APP.pageForm.setBlockContent(blockElt, inst.getData(blockElt));
		},
		
		getData: function(blockElt) {
			let slides = [];
				
			$(blockElt).find('.jumbo-block-slide').each(function() {
				slides.push({
					title: $(this).find('[data-role="slide-title"]').val(),
					description: APP.pageForm.getRteContent($(this).find('[data-role="slide-description"]')),
					image: $(this).find('.raw-image-upload-widget').get(0).rawImageUpload.src,
				});
			})
			
			return {slides: slides};
		},
		
		getTemplate: function(blockElt, scene) {
			let $tpl = $('<div></div>');
			let $addBtn = $(APP.pageForm.getAddBtnStr({
				label: "Ajouter une diapo"
			}));
			
			// Add btn
			$addBtn.on('click', function() {
				inst.addSlide(blockElt, scene);
			});
			$tpl.prepend($addBtn);
			
			// Add bin
			$tpl.append($('<div class="slides-bin"></div>'));
			
			return $tpl;
		},
	
		addSlide: function(blockElt, scene, options) {
			const config = {...{
					autofocus: true,
					title: "Titre de la diapo",
					description: "<p>Entrez votre description ici !</p>",
				}, ...options };
			
			// Create slide & child elts
			let $tpl = $('<div class="jumbo-block-slide border-top container-fluid py-3">');
			
			// buttons
			let $toolbar = $('<div class="btn-group w-100" role="group">');
			let $upBtn = $('<button data-action="slide-up" type="button" class="btn btn-light"><i class="fas fa-arrow-up"></i></button>');
			let $downBtn = $('<button data-action="slide-down" type="button" class="btn btn-light"><i class="fas fa-arrow-down"></i></button>');
			let $deleteBtn = $('<button data-action="slide-delete" type="button" class="btn btn-light"><i class="fas fa-times"></i></button>');
			
			// inputs
			let $titleInput = $(`<textarea data-role="slide-title" class="form-control me-2" rows="1"></textarea>`)
				.val(config.title)
				.on('change', function() {
					inst.onChange(blockElt)
				})
			;
			let $description = $(`<div data-role="slide-description" class="mt-3">`);
			let $imageInput = APP.rawImageUpload.getWidget({
				src: config.image,
				fileVersion: 'lg',
				label: 'Image de fond',
				onChange: function() {
					inst.onChange(blockElt)
				}
			});
			
			// Append
			$toolbar.append($titleInput);
			$toolbar.append($upBtn);
			$toolbar.append($downBtn);
			$toolbar.append($deleteBtn);
			$tpl.append($toolbar);
			$tpl.append($imageInput);
			$tpl.append($description);
			$(scene).find('.slides-bin').prepend($tpl);
			
			// Select when focus on default value
			$titleInput.on('focus', function() {
				if($(this).val() === config.title)
					$(this).select();
			});
			
			// Autofocus
			if(config.autofocus)
				$(scene).find('input').first().focus();
			
			// Init RTE
			// setTimeout(function() {
				APP.pageForm.initRte(blockElt, $description, config.description, inst.onChange, {
					layout: 'minimal',
				})
			// }, 50);
			
			// Toolbar
			$deleteBtn.on('click', function() {
				if(confirm('Supprimer cette diapo ?')) {
					$tpl.slideUp(200, null, function() {
						$tpl.remove();
						inst.updateDisplay(blockElt);
						inst.onChange(blockElt);
					})
				}
			});
			$upBtn.on('click', function() {
				const $binItem = $upBtn.parentsUntil('.slides-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'up')
			});
			$downBtn.on('click', function() {
				const $binItem = $downBtn.parentsUntil('.slides-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'down')
			});
			
			// Update disp
			inst.updateDisplay(blockElt);
		},
		
		updateDisplay: function(blockElt) {
			$(blockElt).find('.jumbo-block-slide').each(function(i) {
				$(this).find('[data-action="slide-up"]').toggleClass('disabled', $(this).is(':first-child'));
				$(this).find('[data-action="slide-down"]').toggleClass('disabled', $(this).is(':last-child'));
			});
		},
		
		moveItem: function(blockElt, scene, $binItem, moveTo) {
			let slides = inst.getData(blockElt)['slides'];
			let $binElts = $binItem.parent().children();
			let oPosition = $binItem.index();
			let newPosition;
			
			// To position number
			switch(moveTo){
				case 'up':		newPosition = oPosition -1; 	break;
				case 'down':	newPosition = oPosition +1; 	break;
				default:		newPosition = moveTo;
			}
			if(newPosition < 0 || newPosition > $binElts.length -1)
				return;
			
			// Change array positions
			inst.arrayMove(slides, oPosition, newPosition);
			
			// clear and reset bin
			$binElts.remove();
			slides.reverse().forEach(slide => {
				inst.addSlide(blockElt, scene, {
					autofocus: false,
					title: slide.title,
					description: slide.description,
					image: slide.image,
				});
			});
		},
		
		arrayMove: function(arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length;
			}
			while (new_index < 0) {
				new_index += arr.length;
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing purposes
		},
	
};
    
    APP.pageForm.addPageBlockMethods('jumbo', inst);
};
