/**
 * Uses 'sf-collections'
 */

module.exports = function (APP, $) {
	var module = {};
	
	const inst = APP.iconInput = {
		
		init: function() {
			if (typeof APP.sfCollection !== "undefined") {
				APP.sfCollection.onAdd.push(function() {
					inst.initRequiredInputs();
				})
			}
			inst.initRequiredInputs();
		},
		
		initRequiredInputs: function() {
			$('[name$="[icon]"]:not(.inited)').each(function() {
				inst.initInput($(this));
			})
		},
		
		initInput: function($input) {
			if($input.prev().hasClass('input-group-text')) {
				$input.on('change', function() {
					inst.updateIcon($input);
				})
			}
			$input.addClass('inited');
		},
		
		updateIcon: function($input) {
            $input.prev().html('<i class="'+($input.val())+' fa-fw"></i>');
		}
	};
	
	// Init on ready
	$(document).ready(function () {
		inst.init();
	});
	
	return module;
};
