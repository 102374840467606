
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					image: $elt.find('.raw-image-upload-widget').get(0).rawImageUpload.src,
					h1: $elt.find('[data-name="h1"]').val(),
					style: $elt.find('[data-name="style"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {
				image: null,
				h1: null,
				style: 'blue',
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// image
			APP.rawImageUpload.getWidget({
				src: config.image,
				fileVersion: 'lg',
				label: 'Image de fond',
				onChange: function() {
					inst.onChange(blockElt)
				}
			})
			.appendTo($tpl)
			;
			
			let $row = $('<div class="row"></div>');
			
			// h1
			$(`<label class="mb-2">Titre principal intégré (H1) :</label>`).appendTo($tpl);
			$(`<textarea data-name="h1" class="form-control mb-2" rows="1"></textarea>`)
			.val(config.h1)
			.attr('placeholder', "Facultatif...")
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-lg-6">').parent()
			.appendTo($row)
			;
			
			// style
			$(`<select class="form-select mb-2" data-name="style"><option value="blue">Bandeau bleu</option><option value="orange">Bandeau jaune</option><option value="purple">Bandeau violet</option></select>`)
			.val(config.style)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent().appendTo($row)
			;
			
			$row.appendTo($tpl);
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('banner', inst);
};
