
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
		
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
		
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
	
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					mapTitle: $elt.find('[data-name="mapTitle"]').val(),
					metasTitle: $elt.find('[data-name="metasTitle"]').val(),
					contactUrl: $elt.find('[data-name="contactUrl"]').val(),
					mapLat: $elt.find('[data-name="mapLat"]').val(),
					mapLng: $elt.find('[data-name="mapLng"]').val(),
					markerLat: $elt.find('[data-name="markerLat"]').val(),
					markerLng: $elt.find('[data-name="markerLng"]').val(),
					markerTitle: $elt.find('[data-name="markerTitle"]').val(),
					zoom: $elt.find('[data-name="zoom"]').val(),
				} };
		},
	
		getDefaultData: function() {
			return {
				mapTitle: null,
				metasTitle: null,
				contactUrl: null,
				mapLat: null,
				mapLng: null,
				markerLat: null,
				markerLng: null,
				markerTitle: null,
				zoom: null,
			};
		},
	
		getTemplate: function(blockElt, scene) {
		
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
		
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
		
			// Infos
			$tpl.append('<p class="text-info text-center my-3"><i class="fas fa-info-circle"></i> ' +
				'La partie coordonnées est mise en forme automatiquement en fonction de ' +
				'<a href="/admin/config">la configuration générale du site</a>.</p>')
			$tpl.append('<label class="my-2">Titres :</label>')
			let $row = $('<div class="row">');
			
			// title
			$(`<input class="form-control mb-2" data-name="mapTitle" placeholder="Titre du formulaire">`)
			.val(config.mapTitle)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
		
			// title2
			$(`<input class="form-control mb-2" data-name="metasTitle" placeholder="Titre des coordonnées">`)
			.val(config.metasTitle)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
			
			// Map config
			$row.append('<div class="col-12 mt-2"><label class="pb-2">Configuration de la carte</label></div>')
			
			// mapLat
			$(`<input class="form-control mb-2" data-name="mapLat" placeholder="Latitude">`)
			.val(config.mapLat)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			// mapLng
			$(`<input class="form-control mb-2" data-name="mapLng" placeholder="Longitude">`)
			.val(config.mapLng)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			// zoom
			$(`<input type="number" class="form-control mb-2" data-name="zoom" placeholder="Zoom" min="0" max="20">`)
			.val(config.zoom)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			// Map config
			$row.append('<div class="col-12 mt-2"><label class="pb-2">Configuration du marker</label></div>')
			
			// markerLat
			$(`<input class="form-control mb-2" data-name="markerLat" placeholder="Latitude">`)
			.val(config.markerLat)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			// markerLng
			$(`<input class="form-control mb-2" data-name="markerLng" placeholder="Longitude">`)
			.val(config.markerLng)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			// marker title
			$(`<input class="form-control mb-2" data-name="markerTitle" placeholder="Titre">`)
			.val(config.markerTitle)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-4">').parent()
			.appendTo($row)
			;
			
			$tpl.append($row);
			
			// map url
			$tpl.append('<label class="my-2">Lien vers le formulaire de contact :</label>')
			APP.pageForm.getLinkUrlInput({
				value: config.contactUrl,
				attributes: {
					'data-name': 'contactUrl',
					'placeholder': "https://...",
				},
				onChange: function(input) {
					inst.onChange(blockElt);
				}
			})
			.appendTo($tpl)
			;
			
			
			return $tpl;
		},
	};
    
    APP.pageForm.addPageBlockMethods('contact-map', inst);
};
