
module.exports = function (APP, $) {
	var module = {};

	const inst = {

		init: function (blockElt, scene) {
			const data = {...inst.getDefaultData(), ...APP.pageForm.getBlockContent(blockElt)};

			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene, data));

			// Load existing items
			if(data.hasOwnProperty('items')) {
				data.items.forEach(item => {
					inst.addItem(blockElt, scene, {
						autofocus: false,
						label: item.label,
						text: item.text,
					});
				});
			}
			// No items
			else {
				inst.addItem(blockElt, scene, {});
			}

			// Save Default data on init
			inst.onChange(blockElt);
		},

		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},

		getDefaultData: function() {
			return {
				items: [],
			}
		},

		getDefaultItemData: function() {
			return {
				label: null,
				text: null,
			};
		},

		getData: function(blockElt) {
			let items = [];
			$(blockElt).find('.kpi-block-item').each(function() {
				items.push({...inst.getDefaultItemData(), ...{
						label: $(this).find('[data-name="item-label"]').val(),
						text: $(this).find('[data-name="item-text"]').val(),
					}});
			});
			return {
				items: items,
			};
		},

		getTemplate: function(blockElt, scene, data) {
			let $tpl = $('<div></div>');

			// Add bin
			$tpl.append($('<div class="items-bin"></div>'));

			// "Add" btn
			$(APP.pageForm.getAddBtnStr({
				label: "Ajouter un bloc"
			})).on('click', function() {
				inst.addItem(blockElt, scene, {
					autofocus: true,
				});
			})
				.appendTo($tpl)
			;

			return $tpl;
		},

		addItem: function(blockElt, scene, options) {
			const config = {...inst.getDefaultItemData(), ...options };

			// Create item & child elts
			let $tpl = $('<div class="kpi-block-item border-top container-fluid py-3">');

			// buttons
			let $toolbar = $('<div class="btn-group w-100 mb-2" role="group">');
			let $upBtn = $('<button data-action="item-up" type="button" class="btn btn-light"><i class="fas fa-arrow-up"></i></button>');
			let $downBtn = $('<button data-action="item-down" type="button" class="btn btn-light"><i class="fas fa-arrow-down"></i></button>');
			let $deleteBtn = $('<button data-action="item-delete" type="button" class="btn btn-light"><i class="fas fa-times"></i></button>');

			// inputs
			let $labelInput = $(`<input data-name="item-label" class="form-control me-2">`)
				.attr('placeholder', "Titre")
				.val(config.label)
				.on('change', function() {
					inst.onChange(blockElt)
				})
			;
			let $textInput = $(`<input data-name="item-text" class="form-control me-2">`)
				.attr('placeholder', "Contenu")
				.val(config.text)
				.on('change', function() {
					inst.onChange(blockElt)
				})
			;

			// Append
			$toolbar.append($labelInput);
			$toolbar.append($upBtn);
			$toolbar.append($downBtn);
			$toolbar.append($deleteBtn);
			$tpl.append($toolbar);
			$tpl.append($textInput);
			$(scene).find('.items-bin').append($tpl);

			// Select when focus on default value
			$labelInput.on('focus', function() {
				if($(this).val() === inst.getDefaultItemData().label)
					$(this).select();
			});

			// Autofocus
			if(config.autofocus)
				$tpl.find('input').first().focus();

			// Toolbar
			$deleteBtn.on('click', function() {
				if(confirm('Supprimer ce lien ?')) {
					$tpl.slideUp(200, null, function() {
						$tpl.remove();
						inst.updateDisplay(blockElt);
						inst.onChange(blockElt);
					})
				}
			});
			$upBtn.on('click', function() {
				const $binItem = $upBtn.parentsUntil('.items-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'up')
			});
			$downBtn.on('click', function() {
				const $binItem = $downBtn.parentsUntil('.items-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'down')
			});

			// Update disp
			inst.updateDisplay(blockElt);
		},

		updateDisplay: function(blockElt) {
			$(blockElt).find('.kpi-block-item').each(function(i) {
				$(this).find('[data-action="item-up"]').toggleClass('disabled', $(this).is(':first-child'));
				$(this).find('[data-action="item-down"]').toggleClass('disabled', $(this).is(':last-child'));
			});
		},

		moveItem: function(blockElt, scene, $binItem, moveTo) {
			let items = inst.getData(blockElt)['items'];
			let $binElts = $binItem.parent().children();
			let oPosition = $binItem.index();
			let newPosition;

			// To position number
			switch(moveTo){
				case 'up':		newPosition = oPosition -1; 	break;
				case 'down':	newPosition = oPosition +1; 	break;
				default:		newPosition = moveTo;
			}
			if(newPosition < 0 || newPosition > $binElts.length -1)
				return;

			// Change array positions
			inst.arrayMove(items, oPosition, newPosition);

			// clear and reset bin
			$binElts.remove();
			items.forEach(item => {
				inst.addItem(blockElt, scene, {
					autofocus: false,
					label: item.label,
					text: item.text,
				});
			});
		},

		arrayMove: function(arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length;
			}
			while (new_index < 0) {
				new_index += arr.length;
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing purposes
		},

	};

	APP.pageForm.addPageBlockMethods('kpi', inst);
};
