
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
		
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
		
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
	
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					formTitle: $elt.find('[data-name="formTitle"]').val(),
					metasTitle: $elt.find('[data-name="metasTitle"]').val(),
					mapUrl: $elt.find('[data-name="mapUrl"]').val(),
				} };
		},
	
		getDefaultData: function() {
			return {
				formTitle: null,
				metasTitle: null,
			};
		},
	
		getTemplate: function(blockElt, scene) {
		
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
		
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
		
			// Infos
			$tpl.append('<p class="text-info text-center my-3"><i class="fas fa-info-circle"></i> ' +
				'Le formulaire de contact est mis en forme automatiquement, en fonction de ' +
				'<a href="/admin/config">la configuration générale du site</a>.</p>')
			$tpl.append('<label class="my-2">Titres :</label>')
			let $row = $('<div class="row">');
			
			// title
			$(`<input class="form-control mb-2" data-name="formTitle" placeholder="Titre du formulaire">`)
			.val(config.formTitle)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
		
			// title2
			$(`<input class="form-control mb-2" data-name="metasTitle" placeholder="Titre des coordonnées">`)
			.val(config.metasTitle)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
			
			$tpl.append($row);
			
			// map url
			$tpl.append('<label class="my-2">Lien vers le plan d\'accès :</label>')
			APP.pageForm.getLinkUrlInput({
				value: config.mapUrl,
				attributes: {
					'data-name': 'mapUrl',
					'placeholder': "https://...",
				},
				onChange: function(input) {
					inst.onChange(blockElt);
				}
			})
			.appendTo($tpl)
			;
			
			return $tpl;
		},
	};
    
    APP.pageForm.addPageBlockMethods('contact-form', inst);
};
