/**
 * Bouton d'ajout d'élément :
 * Ajouter un lien [data-action=addToCollection] + [data-target=ID_DU_CONTAINER_PROTOTYPE]
 *
 * Bouton de suppression d'élément :
 * Ajouter dans le template du formulaire enfant le lien [data-action=removeFromCollection]
 *
 * Ajouter un message «Le chutier est vide» :
 * Ajouter à l'entrée du formulaire la propriété « data-emptymessage » (exeple ci-dessous)
 *
 * Exemple d'implémentation dans le formulaire parent :
	->add('memberships', CollectionType::class, [
		'required' => false,
		'label' => 'agency.memberships',
		'entry_type' => AgencyMembershipType::class,
		'allow_add' => true,
		'allow_delete' => true,
		'attr' => [
			'class' => 'list-group',
			'data-emptymessage' => $this->tl->trans('bin_is_empty'),
		]
		'entry_options' => [
			'label' => false
		]
	])
 *
 */
APP.sfCollection = {
	
	// Callbacks
	onAdd: [],
	
	init: function(){
		var inst = this;
		
		// Ajouter un élément de collection
		$('body').on('click', '[data-action=addToCollection]', function(){
			let $collectionContainer = $('#'+$(this).data('target'));
			if($collectionContainer.length)
				inst.addToCollection($collectionContainer);
			else
				alert('Collection container not found');
		});
		
		// Retirer un élément de collection spawné via prototype ou non
		$('form').on('click', '[data-action=removeFromCollection]', function(){
            let confirmMessage = $(this).data('confirm-message');
            if(!confirmMessage || confirm(confirmMessage))
                inst.removeFromCollection(
                    $(this).parentsUntil('[data-prototype]').last()
                );
		});
		
		// Message "bin is empty"
		$('[data-prototype][data-emptymessage]').each(function(){
			inst.updateEmptyMessage($(this));
		});
		
		// TODO Dupliquer un élément de collection
	},
	
	updateEmptyMessage: function($collectionContainer){
		
		// Ignore if unset
		if(!$collectionContainer.attr('data-emptymessage'))
			return;
		
		// First init
		let $elt = $collectionContainer.prev('.emptymessage');
		if($elt.length === 0){
			$elt = $('<div class="emptymessage card m-0 px-3 py-2 text-muted"></div>')
			.css('font-style', 'italic')
			.text($collectionContainer.attr('data-emptymessage'))
			.hide()
			;
			$elt.insertBefore($collectionContainer);
		}
		
		// Toggle
		let hasChildren = $collectionContainer.find('>*').length > 0;
		if(hasChildren)
			$elt.slideUp('fast');
		else
			$elt.slideDown('fast');
	},
	
	/**
	 * Retirer l'élément
	 * @param $element
	 */
	removeFromCollection: function($element){
		let inst = this;
		if(!$element.parent().is('[data-prototype]'))
			$element = $element.parentsUntil('[data-prototype]');
		let $collectionContainer = $element.parents('[data-prototype]').first();
		let elt = $element.get(0);
		
		// Custom event
		elt.dispatchEvent(
			new CustomEvent('removeFromCollection', {
				bubbles: true,
				detail: { }
			})
		);
		
		$element.slideUp("fast", function() {
			elt.dispatchEvent(
				new CustomEvent('removedFromCollection', {
					bubbles: true,
					detail: { }
				})
			);
			
			$element.remove();
			
			setTimeout(function(){
				inst.updateEmptyMessage($collectionContainer);
			}, 150);
		});
	},
	
	addToCollection: function($collectionContainer, options = {}){
		const collectionContainer = $collectionContainer.get(0);
		let prototype = $collectionContainer.data('prototype');
		let tmpIndex;
		options = {...{
            autofocus: true,
            animate: true,
            searchAndReplace: [],
        }, ...options };

		// Index = Nombre d'éléments au chargement de la page
		let newIndex = $collectionContainer.find('>*').length;
		
		// Après ajout puis suppression, il y a des mouvements sur l'index.
		// Les éléments ajoutés dynamiquement disposent d'un index "en dur"
		$collectionContainer.find('>[data-index]').each(function(){
			tmpIndex = parseInt($(this).data('index'));
			if(tmpIndex >= newIndex)
				newIndex = tmpIndex +1;
		})
		
		// You need this only if you didn't set 'label' => false in your tags field in TaskType
		// Replace '__name__label__' in the prototype's HTML to
		// instead be a number based on how many items we have
		// newForm = newForm.replace(/__name__label__/g, index);
		
		// Replace '__name__' in the prototype's HTML to
		// instead be a number based on how many items we have
		
		// Ne pas remplacer les valeurs dans le prototype enfant:
		// Récupérons la PREMIERE valeur à remplacer, qui logiquement correspond à
		// _entityName__name__  et remplaçons cette valeur là.
		var match = prototype.match(/_[a-zA-Z0-9]+___name__/)[0];
		var entityName = match.substr(1, match.length - 10);
		var replace, regex;
		
		// NIVEAU 0
		// Remplacement de l'index
		replace = 'data-index="__name__"';
		regex = new RegExp(replace);
		prototype = prototype.replace(regex, 'data-index="'+newIndex+'"');
		
		// Remplacement des ID
		replace = entityName + "___name__";
		regex = new RegExp(replace, "g");
		prototype = prototype.replace(regex, entityName+"_"+newIndex);
		
		// Remplacement des Names
		replace = '\\[' + entityName + '\\]\\[__name__\\]';
		regex = new RegExp(replace, "g");
		prototype = prototype.replace(regex, '[' + entityName + '][' + newIndex + ']');
		
		// NIVEAU 1 : Embedded prototype
		// Remplacement des Names
		replace = '&#x5B;' + entityName + '&#x5D;&#x5B;__name__&#x5D;';
		regex = new RegExp(replace, "g");
		prototype = prototype.replace(regex, '&#x5B;' + entityName + '&#x5D;&#x5B;' + newIndex + '&#x5D;');
		
		// NIVEAU 2 : Maybe later...
		// ...

        // Remplacements additionnels
        options.searchAndReplace.forEach(function (row) {
            prototype = prototype.replace(row.search, row.replace);
        });

        // Append
		let $newItem = $(prototype);
		$newItem.attr('data-index', newIndex);
		
		// Spawn nicely
		if(options.animate)
			$newItem.hide();
		$collectionContainer.append($newItem);
		if(options.animate)
			$newItem.slideDown('fast');
		
		// Focus on it
        if(options.autofocus)
		    $newItem.find('input, select').first().focus();
		
		// Remove empty message
		this.updateEmptyMessage($collectionContainer);
		
		// Run callbacks
		this.onAdd.forEach(function(callback){
			callback($newItem.get(0), $collectionContainer.get(0));
		});
		
		// Custom event
		collectionContainer.dispatchEvent(
			new CustomEvent('addToCollection', {
				bubbles: true,
				detail: {
					wrapper: collectionContainer,
					newItem: $newItem.get(0),
				}
			})
		);

        return $newItem;
	},
	
};

// Init on load
$(document).ready(function() {
	APP.sfCollection.init();
});

