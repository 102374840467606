/**
 * Requires 'sf-collections'
 */

module.exports = function (APP, $) {
	var module = {};
	
	const inst = APP.fullLinkType = {
		maxLevel: 2,
		maxInfoLevel: 2,
		infoLevel: 2,
		$moreInfoBtns: null,
		$lessInfoBtns: null,
		
		init: function() {
			
			// Local data
			if(null !== localStorage.getItem('infoLevel'))
				inst.infoLevel = parseInt(localStorage.getItem('infoLevel'));
			
			// On add item
            if (typeof APP.sfCollection !== "undefined") {
                APP.sfCollection.onAdd.push(function(elt, bin) {
                    // whitelist
                    if(!$(bin).is('#menu_links'))
                        return;
                    
                	const newPosition = inst.getMaxFieldPosition($(bin)) +1;
                    inst.initRequired();
                    inst.setPosition(elt, newPosition);
                    inst.updateRow(elt);

					setTimeout(function() {
						elt.scrollIntoView();
					}, 250);
                })
            }

            inst.initRequired();
            inst.updateAllRows($('.full-link-type').get(0));
			
			inst.$lessInfoBtns = $('[data-action="linkLessInfo"]').on('click', function() {
				inst.lessInfo(this);
			});
			inst.$moreInfoBtns = $('[data-action="linkMoreInfo"]').on('click', function() {
				inst.moreInfo(this);
			});
			inst.updateInfoLevel();
            
            // DEV : Fix les erreurs de position au chargement : L'ordre est étable server-side
            // inst.setPositionFromDomOrder();
            
            // Placer les éléments dans l'ordre des inputs
            inst.setCssPositionFromFields();

            // Avoir une suite de positions logique
            inst.resetFieldsPositions($('[data-prototype]'));
            inst.updateAllRows($('.full-link-type').get(0));
		},
		
		initRequired: function() {
			$('.full-link-type:not(.inited)').each(function(){
				inst.initRow(this);
			});
		},
		
		initRow: function(elt) {
			const $elt = $(elt);
			
			elt.$labelInput = $elt.find('[name$="[label]"]');
			elt.$typeInput = $elt.find('[name$="[type]"]');
			elt.$pageInput = $elt.find('[name$="[page]"]');
			elt.$pageInputWrapper = elt.$pageInput.parentsUntil('.row').last();
			elt.$uriInput = $elt.find('[name$="[uri]"]');
			elt.$uriInputWrapper = elt.$uriInput.parentsUntil('.row').last();
			elt.$positionInput = $elt.find('[name$="[position]"]');
			elt.$levelInput = $elt.find('[name$="[level]"]');
			elt.$upBtn = $elt.find('[data-action="up"]');
			elt.$downBtn = $elt.find('[data-action="down"]');
			elt.$toParentBtn = $elt.find('[data-action="toParent"]');
			elt.$toChildBtn = $elt.find('[data-action="toChild"]');
			elt.$isDisabledInput = $elt.find('[name$="[isDisabled]"]');
			elt.$ifEnabled = $elt.find('[data-if-enabled]');
			elt.$ifDisabled = $elt.find('[data-if-disabled]');
			elt.infoLevel = inst.infoLevel;

			// Any field
			$elt.find('input, select').on('change', function() {
				inst.updateRow(elt);
			});
			
			// Enable-disable
			$elt.on('click', '[data-action="linkToggleDisabled"]', function() {
				elt.$isDisabledInput.prop('checked', !elt.$isDisabledInput.prop('checked'));
				inst.updateRow(elt);
			});
			
			// Up-down
			$elt.on('click', '[data-action="up"]', function() {
				inst.up(elt);
			});
			$elt.on('click', '[data-action="down"]', function() {
				inst.down(elt);
			});
			
			// Child-parent
			$elt.on('click', '[data-action="toChild"]', function() {
				inst.toChild(elt);
			});
			$elt.on('click', '[data-action="toParent"]', function() {
				inst.toParent(elt);
			});
			
			// Mark
			$elt.addClass('inited');
		},
		
		lessInfo: function(btn = null) {
            const $linkItem = $(btn).parents('.full-link-type').first();
            if($linkItem.length > 0){
                if($linkItem.get(0).infoLevel > 0)
                    $linkItem.get(0).infoLevel--;
            }
            else{
                if(inst.infoLevel > 0)
                    inst.infoLevel--;
                localStorage.setItem('infoLevel', inst.infoLevel);
            }

			inst.updateInfoLevel($linkItem.get(0));
		},
		
		moreInfo: function(btn = null) {
            const $linkItem = $(btn).parents('.full-link-type').first();
            if($linkItem.length > 0){
                if($linkItem.get(0).infoLevel < inst.maxInfoLevel)
                    $linkItem.get(0).infoLevel++;
            }
            else{
                if(inst.infoLevel < inst.maxInfoLevel)
                    inst.infoLevel++;
                localStorage.setItem('infoLevel', inst.infoLevel);
            }

			inst.updateInfoLevel($linkItem.get(0));
		},
		
		updateInfoLevel: function(linkItem = null) {

            // All in page, or specific item
            let $lessInfoBtns = linkItem
                ? $(linkItem).find('[data-action="linkLessInfo"]')
                : inst.$lessInfoBtns
            ;
            let $moreInfoBtns = linkItem
                ? $(linkItem).find('[data-action="linkMoreInfo"]')
                : inst.$moreInfoBtns
            ;
            let items = linkItem
                ? [linkItem]
                : $('.full-link-type').toArray()
            ;
            let infoLevel = linkItem
                ? linkItem.infoLevel
                : inst.infoLevel
            ;

            // enable/disable btns
			$lessInfoBtns.toggleClass('disabled', infoLevel <= 0);
			$moreInfoBtns.toggleClass('disabled', infoLevel >= inst.maxInfoLevel);

            items.forEach(function(item) {

                // show/hide contents by level
                $(item).find('[data-info-level]').each(function() {
                    $(this).toggleClass('d-none', parseInt($(this).attr('data-info-level')) > infoLevel);
                });

                // Element title
                $(item).find('.minimal-label').text(
                    infoLevel === 0
                        ? item.$labelInput.val()
                        : ''
                );
            });

		},
		
		getBin: function(elt) {
			return $(elt).parents('[data-prototype]').first();
		},

		getEltsSortedByFieldPosition: function($bin) {
			let arr = [];
			$bin.find('>*').each(function() {
				this.tmpPosition = inst.getFieldPosition(this);
				arr.push(this);
			});
			arr.sort((elt1, elt2) => elt1.tmpPosition - elt2.tmpPosition);
			return arr;
		},
		
		getMaxFieldPosition: function($bin) {
			let max = 0;
			$bin.find('>*').each(function() {
				let position = inst.getFieldPosition(this);
				if(max < position)
					max = position;
			});
			return max;
		},
		
		getChildren: function(elt) {
			const $bin = inst.getBin(elt);
			let sortedArr = inst.getEltsSortedByFieldPosition($bin);
			let childrenArr = [];
			let foundElt = null;
			
			sortedArr.forEach(tmpElt => {
				if(tmpElt === elt){
					foundElt = tmpElt;
					return;
				}
				if(!foundElt)
					return;
				if(inst.getLevel(tmpElt) > inst.getLevel(elt))
					childrenArr.push(tmpElt);
				else
					foundElt = null;
			})
			
			return childrenArr;
		},
		
		updateAllRows: function(elt) {
			// Elements dans l'ordre de position
			const sortedElts = inst.getEltsSortedByFieldPosition(
				inst.getBin(elt)
			);
			for (let i = 0; i < sortedElts.length; i++) {
				inst.updateRow(sortedElts[i]);
			}
		},
		
        updateRow: function(elt) {
            const type = elt.$typeInput.val();
            const $elt = $(elt);
            const position = inst.getFieldPosition(elt);
            const prevElt = inst.getElementWithFieldPosition(position -1);
			
            // Enabled disabled
			$elt.toggleClass('is-disabled', elt.$isDisabledInput.prop('checked'));
			elt.$ifDisabled.toggleClass('d-none', !elt.$isDisabledInput.prop('checked'));
			elt.$ifEnabled.toggleClass('d-none', elt.$isDisabledInput.prop('checked'));
			
            // Display fields
            elt.$pageInputWrapper.toggle('page' === type);
            elt.$uriInputWrapper.toggle('page' !== type);

            // Up-down btns
            elt.$upBtn.toggleClass('disabled', 0 === inst.getFieldPosition(elt));
            elt.$downBtn.toggleClass('disabled', inst.countBin(elt) -1 === inst.getFieldPosition(elt));

            // Update level possibilities
			let level = elt.$levelInput.val();
			let minLevel = 0;
			let maxLevel = prevElt
				? inst.getLevel(prevElt) +1 > inst.maxLevel
					? inst.maxLevel
					: inst.getLevel(prevElt) +1
				: 0
			;
			if(level < minLevel)
				level = minLevel;
			if(level > maxLevel)
				level = maxLevel;
			
			// Update level
			inst.setLevel(elt, level);

			// Level btns
			elt.$toParentBtn.toggleClass('disabled', level <= minLevel);
			elt.$toChildBtn.toggleClass('disabled', level >= maxLevel);

            // DEV
            // if("Concert hors les murs" === $(elt).find('[name$="[label]"]').val()) {
            //
            // }
		},
		
		setLevel: function(elt, level) {
			$(elt).attr('data-level', level);
			elt.$levelInput.val(level);
		},
		
		getLevel: function(elt) {
			return parseInt($(elt).attr('data-level'));
		},

        countBin: function(elt) {
            return $(elt).siblings().length +1;
        },

        up: function(elt) {
            const oPosition = inst.getFieldPosition(elt);
            const swappedElt = inst.getElementWithFieldPosition(oPosition -1);
            if(!swappedElt)
                return;
			
            inst.setPosition(elt, oPosition -1);
			inst.setPosition(swappedElt, oPosition);
            inst.updateAllRows(elt);
			
            elt.$upBtn.focus();
            // elt.scrollIntoView({ behaviour: "smooth" });
        },

        down: function(elt) {
            const oPosition = inst.getFieldPosition(elt);
            const $swappedElt = inst.getElementWithFieldPosition(oPosition +1);
            if(0 === $swappedElt.length)
                return;
            
            inst.setPosition(elt, oPosition +1);
            inst.setPosition($swappedElt, oPosition);
            inst.updateAllRows(elt);
            
            elt.$downBtn.focus();
            // elt.scrollIntoView({ behaviour: "smooth" });
        },
		
		toChild: function(elt, updateAll=true) {
			let level = parseInt(elt.$levelInput.val());
			if(level >= inst.maxLevel)
				return;
			
			elt.$levelInput.val(level +1);
			
			// Recursive to children
            inst.getChildren(elt).forEach(child => inst.toChild(child, false));
            
			if(updateAll)
				inst.updateAllRows(elt);
		},
		
		toParent: function(elt, updateAll=true) {
			let level = parseInt(elt.$levelInput.val());
			if(level <= 0)
				return;
			
			elt.$levelInput.val(level -1);
			
			// Recursive to children
            inst.getChildren(elt).forEach(child => inst.toParent(child, false));
            
			if(updateAll)
				inst.updateAllRows(elt);
		},

        getElementWithFieldPosition: function(position) {
            let elt = null;
            $('.full-link-type').each(function(){
                if(position === inst.getFieldPosition(this)){
                    elt = this;
                    return;
                }
            });
            return elt;
        },

		// dev
        setPositionFromDomOrder: function() {
            $('.full-link-type').each(function(i) {
                inst.setPosition($(this), i);
            })
        },

        setCssPositionFromFields: function() {
            $('.full-link-type').each(function(i) {
                inst.setCssPosition($(this), inst.getFieldPosition(this));
            })
        },

        resetFieldsPositions: function($bin) {
            const sortedElts = inst.getEltsSortedByFieldPosition($bin);
            for(let i = 0; i < sortedElts.length; i++){
                inst.setFieldPosition(sortedElts[i], i);
            }
        },

        getFieldPosition: function(elt) {
            return parseInt($(elt).find('[name$="[position]"]').val());
        },

		// Both field & css
        setPosition: function(elt, position) {
            inst.setFieldPosition(elt, position);
            inst.setCssPosition(elt, position);
        },
		
		setFieldPosition: function(elt, position) {
			$(elt).find('[name$="[position]"]').val(position);
		},
		
		setCssPosition: function(elt, position) {
			$(elt).css({
				'order': position,
				'webkit-order': position,
			});
		},

	};
	
	// Init on ready
	$(document).ready(function () {
		APP.fullLinkType.init();
	});
	
	return module;
};
