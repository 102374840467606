
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					style: $elt.find('[data-name="style"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {
				style: 'darkblue',
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// style
			$(`<select class="form-select mb-2" data-name="style"><option value="darkblue">Style : Bleu sombre</option><option value="purple">Style : Violet</option></select>`)
			.val(config.style)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.appendTo($tpl)
			;
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('separator', inst);
};
