
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					label: $elt.find('[data-name="label"]').val(),
					type: $elt.find('[data-name="type"]').val(),
					target: $elt.find('[data-name="target"]').val(),
					url: $elt.find('[data-name="url"]').val(),
					image: $elt.find('.raw-image-upload-widget').get(0).rawImageUpload.src,
				} };
		},
	
		getDefaultData: function() {
			return {
				label: null,
				type: "url",
				target: "_self",
				url: null,
				image: null,
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// label
			$(`<textarea data-name="label" class="form-control mb-2" rows="1"></textarea>`)
				.val(config.label)
				.attr('placeholder', "Texte du lien...")
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.appendTo($tpl)
			;
			let $row = $('<div class="row">');
			
			// type
			$(`<select class="form-select mb-2" data-name="type"><option value="url">Lien vers une page web</option><option value="file">Fichier à télécharger</option></select>`)
				.val(config.type)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.wrap('<div class="col-md-6">').parent().appendTo($row)
			;
			
			// target
			$(`<select class="form-select mb-2" data-name="target"><option value="_self">Ouvrir dans la même page</option><option value="_blank">Ouvrir dans un nouvel onglet</option></select>`)
				.val(config.target)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.wrap('<div class="col-md-6">').parent().appendTo($row)
			;
			$tpl.append($row);
			
			// url
			APP.pageForm.getLinkUrlInput({
					value: config.url,
					attributes: {
						'data-name': 'url',
						'placeholder': "URL...",
					},
					onChange: function(input) {
						inst.onChange(blockElt);
						setTimeout(function() {
							$(input).focus().select();
						}, 200);
					}
				})
				.appendTo($tpl)
			;
			
			// image
			APP.rawImageUpload.getWidget({
					src: config.image,
					fileVersion: 'md',
					label: 'Illustration <small class="text-muted">(facultative)</small>',
					onChange: function() {
						inst.onChange(blockElt)
					}
				})
				.appendTo($tpl)
			;
			
			return $tpl;
		},
};
    
    APP.pageForm.addPageBlockMethods('big-link', inst);
};
