/**
 * Requires 'sf-collections'
 */

module.exports = function (APP, $, tinymce, Fancybox) {
	var module = {};
	
	const inst = APP.pageForm = {
		$form: null,
		$pageBlocksBin: null,
		checkForChange: true,
		persistedData: null,
		pageBlockMethods: [],
		displayHelp: true,
		
		init: function() {
			
			inst.$form = $('form[name=page]').first();
			
			if(this.$form.length < 1)
				return;
			
			this.$statusInput = inst.$form.find('[name$="[status]"]');
			this.$isHomepageInput = inst.$form.find('[name$="[isHomepage]"]');
			this.$movedUriInput = inst.$form.find('[name$="[movedUri]"]');
			this.$pageBlocksBin = inst.$form.find('[data-prototype]');
			
			// Submits
			this.$form.find('button[type="submit"]').on('click', function(){
				let hasMissingFields = inst.openMissingFieldContainer();
				// Submit OK
				if(!hasMissingFields){
					inst.checkForChange = false;
				}
			});
			
			// No "Enter key" submit
			inst.$form.on("keydown", ":input:not(textarea)", function(event) {
				return event.key !== "Enter";
			});
			
			// Check for change on page leave
			window.onbeforeunload = function(e){
				if(inst.hasChanged() && inst.checkForChange){
					return "Certains contenus n'ont pas été sauvegardés. Voulez-vous vraiment quitter ?";
				}
			};
			
			// Init each existing pageBlock
			$('.page-block-widget-wrapper').each(function(){
				inst.initPageBlock(this);
			});
			
			// Spawn page block
			$('body').on('click', '[data-action="addPageBlock"]', function() {
				inst.addPageBlock(this);
			});
			
			// Wrap / unwrap block
			inst.$form.on('click', '[data-action="wrapToggleContentBlock"]', function() {
				const $binElt = inst.getBlockBinElt(this);
				const $wrapper = $binElt.find('.page-block-widget-wrapper');
				const $wrappedContent = $wrapper.find('.card-body');
				
				$wrapper.toggleClass('wrapped', true === $wrappedContent.is(':visible'));
				$wrappedContent.slideToggle();
			});
			
			// Wrap all on load?
			$('.page-block-widget-wrapper').addClass('wrapped').find(' > .card-body').hide();
			
			// On deleteD page block
			// inst.$form.on('click', '[data-action="removedFromCollection"]', function(){
				// TODO: update blocks positions
			// });
			
			// On status change
			inst.$statusInput.on('change', function(){
				inst.updateFormDisplay();
			});
			
			// Disable / enable
			inst.$form.on('click', '[data-action="toggleContentBlock"]', function() {
				const $binElt = inst.getBlockBinElt(this);
				const $input = $binElt.find('[name$="[isDisabled]"]');
				
				$input.prop('checked', !$input.prop('checked'));
				inst.updatePageBlockDisplay($binElt.get(0));
			});
			
			// Move up / down
			inst.$form.on('click', '[data-action^="moveContentBlock"]', function() {
				const elt = inst.getBlockBinElt(this).get(0);
				let position = inst.getEltPosition(elt);
				
				if('moveContentBlockUp' === $(this).data('action'))
					position--;
				if('moveContentBlockDown' === $(this).data('action'))
					position++;
				
				inst.moveEltTo(elt, position);
				inst.updateFormDisplay();
			});
			
			// Toggle help
			$('[data-action=toggleHelp]').on('click', function() {
				inst.displayHelp = !inst.displayHelp;
				inst.updateFormDisplay();
			});
			
			// Init blocks positions on load by their DOM position
			this.resetEltsPosition(
				inst.getChildrenAsArray(inst.$pageBlocksBin.get(0))
			);
			
			// Update display
			inst.updateFormDisplay();
			
			// Données du formulaire lors du chargement de la page
			this.persistedData = this.$form.serializeArray();
			
		},
		
		
		// ############## ADD BLOCK
		
		addPageBlock: function(button, isClosingBlock=false) {
			const block = $(button).data('block');
			
			// Double-parted block: Wrappers - Add closing block first, then opening block to focus on
			if(block.isWrapper && true !== isClosingBlock)
				inst.addPageBlock(button, true);
			
			// Vars
			const icon = block.icon
				? '<i class="fas fa-'+block.icon+'"></i>'
				: block.htmlIcon
			;
			let nextSpawnPosition;
			
			// Update position inside bin
			if($(button).hasClass('is-top-toolbar'))
				nextSpawnPosition = 0;
			else
				nextSpawnPosition = parseInt(this.getBlockBinElt(button).css('order')) + 1;
			
			// Spawn LAST in bin
			let $newItem = APP.sfCollection.addToCollection(inst.$pageBlocksBin, {
				autofocus: false,
				animate: false === isClosingBlock,
				searchAndReplace: [
					{search: 'TEMPLATE_SLUG', replace: block.slug },
					{search: 'TEMPLATE_NAME', replace: block.name },
					{search: 'TEMPLATE_ICON', replace: icon },
				]
			});
			
			// Update position: last in bin
			const position = inst.$pageBlocksBin.children().length -1; // nombre d'enfants
			inst.setEltPosition($newItem.get(0), position);
			
			// Update position: move to spawner (btn) position
			inst.moveEltTo($newItem.get(0), nextSpawnPosition);
			
			// Update data
			$newItem.find('[name$="[templateSlug]"]').val(block.slug);
			$newItem.find('[name$="[content]"]').val('{}');
			$newItem.find('[name$="[isClosingBlock]"]').prop("checked", isClosingBlock);
			
			// Init
			inst.initPageBlock($newItem.find('.page-block-widget-wrapper').get(0));
			
			// Closing elements. See "isWrapper"
			if(isClosingBlock){
				// Change closing blocks design
				$newItem.addClass('is-closing-block');
				$newItem.find('.page-block-widget-wrapper').addClass('is-closing-block');
				// Stop here
				return;
			}
			
			// Close selector
			$(button).parents('.collapse').get(0).collapse.hide();
			
			// Set bootstrap
			APP.bootstrapHandler.init();
			
			// Update display for all blocks
			inst.updateFormDisplay();
		},
		
		addPageBlockMethods: function(slug, methods){
			this.pageBlockMethods[slug] = methods;
		},
		
		
		// ############## POSITIONS
		
		getChildrenAsArray: function(elt, sorting='dom') {
			let arr = [];
			$(elt).children().each(function(i) {
				let item = this;
				let position = 'dom' === sorting
					? position = i // position in DOM
					: position = inst.getEltPosition(item, i); // Programmatic position
				arr[position] = this;
			});
			return Object.assign([], arr); // Debug ffox console
		},
		
		getEltPosition: function(elt, defaultPosition = 0) {
			return parseInt($(elt).css('order'));
		},
		
		setEltPosition: function(elt, position) {
			$(elt).css('order', position);
			let $positionInput = $(elt).find('[name$="[position]"]');
			if($positionInput.length)
				$positionInput.val(position);
		},
		
		resetEltsPosition: function(arr) {
			for (let i = 0; i < arr.length; i++) {
				let elt = arr[i];
				this.setEltPosition(elt, i);
			}
		},
		
		moveEltTo: function(elt, newPosition) {
			const inst = this;
			
			// Get current sorted array
			let arr = inst.getChildrenAsArray(inst.$pageBlocksBin.get(0), 'css');
			
			// Current item position
			let position = inst.getEltPosition(elt);
			
			// Get info about replaced element
			let targetPositionElement = arr[newPosition];
			
			// Prevent open-close blocks to cross each other: If blocks have the same slug
			if(inst.getBlockSlug(targetPositionElement) === inst.getBlockSlug(elt)){
				// If one of the blocks is a clsing element
				if($(targetPositionElement).find('.is-closing-block').length > 0 || $(elt).find('.is-closing-block').length > 0){
					// Prevent move
					alert('Les blocs ouvrant/fermants ne peuvent pas se croiser.')
					return;
				}
			};
			
			// Move target in array
			let tmpElt = arr.splice(position, 1)[0]; // remove current item and store it
			arr.splice(newPosition, 0, tmpElt); // insert stored item into position `to`
			
			// Apply
			this.resetEltsPosition(arr);
		},
		
		
		// ############## UPDATE DISPLAY
		
		updateFormDisplay: function() {
			// Status-related
			let status = String(this.$statusInput.val());
			$('[data-if-status]').each(function(){
				$(this).toggleClass('d-none', !String($(this).data('if-status')).includes(status));
			});
			let movedUriRequired = status.includes('307') || status.includes('301');
			this.$movedUriInput
			.prop('required', movedUriRequired)
			.attr('required', movedUriRequired);
			
			// Help
			$('.if-no-help').toggleClass('d-none', inst.displayHelp);
			$('.if-help, .help-text').toggleClass('d-none', !inst.displayHelp);
			
			// Each block
			inst.$pageBlocksBin.children().each(function() {
				inst.updatePageBlockDisplay(this);
			})
			
		},
		
		updatePageBlockDisplay: function(elt) {
			let $binElt = inst.getBlockBinElt(elt);
			
			// up / down arrows
			let position = inst.getEltPosition(elt);
			$(elt).find('[data-action="moveContentBlockUp"]').toggleClass('disabled',
				position === 0
			);
			$(elt).find('[data-action="moveContentBlockDown"]').toggleClass('disabled',
				position === inst.$pageBlocksBin.children().length -1
			);
			
			// Enable / disable
			let disabled = $(elt).find('[name$="[isDisabled]"]').prop('checked');
			$binElt.toggleClass('disabled', disabled).toggleClass('enabled', !disabled);
		},
		
		
		// ############## BLOCKS INPUTS TEMPLATES
		
		getAddBtnStr: function(options = {}) {
			const config = {...{
					label: null,
					title: "Ajouter...",
				}, ...options };
			return '<button role="button" type="button" class="btn btn-light btn-sm m-2" title="'+config.title+'"><i class="fas fa-plus"></i>'+(config.label?' '+config.label:'')+'</button>'
		},
		
		getLinkUrlInput: function(options = {}) {
			const config = {...{
					value: null,
					attributes: {'data-name': 'url'},
					browseFilesLabel: 'Parcourir les fichiers',
					browsePagesLabel: 'Parcourir les pages du site',
					fileVersion: '_original',
					onChange: function(input){},
				}, ...options };
			
			const $tpl = $('<div class="input-group mb-2">');
			let attrStr = ' ';
			for (let key in config.attributes)
				attrStr += key+'="'+config.attributes[key]+'" ';
			const $input = $(`<input type="text" ${attrStr} class="form-control">`)
				.val(config.value)
				.on('change', function() {
					config.onChange(this)
				})
			;
			const $browsePagesBtn = $(`<button type="button" class="btn btn-light" title="${config.browsePagesLabel}"><i class="fas fa-sitemap"></i></button>`);
			const $browseFilesBtn = $(`<button type="button" class="btn btn-light" title="${config.browseFilesLabel}"><i class="fas fa-folder"></i></button>`);
			
			// Actions
			$browseFilesBtn.on('click', function() {
				const fancybox = new Fancybox([{
					src: '/files/browse',
					type: "iframe",
					preload: false,
				}], {
					on: {
						done: function() {
							const iframe = $('.fancybox__iframe').get(0);
							iframe.onload = function() {
								const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
								// @see files.js
								iframeDocument.onFileSelect = iframeDocument.onFileUpload = function(file) {
									$input.val(file.paths[config.fileVersion]).trigger('change').focus().select();
									fancybox.close();
								};
							}
						}
					}
				});
			});
			
			// Actions
			$browsePagesBtn.on('click', function() {
				const fancybox = new Fancybox([{
					src: '/pages/browse',
					type: "iframe",
					preload: false,
					// scrolling: 'no',
				}], {
					on: {
						done: function() {
							const iframe = $('.fancybox__iframe').get(0);
							iframe.onload = function() {
								const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
								// @see files.js
								iframeDocument.onPageSelect = function(page) {
									$input.val(page.relativePath).trigger('change').focus().select();
									fancybox.close();
								};
							}
						}
					}
				});
			});
			
			$tpl.append($browsePagesBtn);
			$tpl.append($browseFilesBtn);
			$tpl.append($input);
			
			return $tpl;
		},
		
		
		// ############## OTHERS
		
		submit: function(){
			this.$form.submit();
		},
		
		hasChanged: function(){
			// Non-optimal method
			return JSON.stringify(this.$form.serializeArray()) !== JSON.stringify(this.persistedData);
		},
		
		initPageBlock: function(elt) {
			const $elt = $(elt);
			const slug = this.getBlockSlug(elt);
			let $bin = $elt.parents('[data-prototype]').first();
			let $binElt = $elt.parent().is('[data-prototype]')
				? $elt
				: $elt.parentsUntil('[data-prototype]').last()
			;
			const binElt = $binElt.get(0);
			
			// Errors
			if(!slug)
				return console.error("PageBlocks slug not defined");
			if(true !== this.pageBlockMethods.hasOwnProperty(slug))
				return console.error("PageBlocks have no methods for slug "+slug);
			
			// Init
			this.pageBlockMethods[slug].init(
				$elt.get(0),
				$elt.find('.page-block-widget-scene').get(0)
			);
			
			// Display
			this.updatePageBlockDisplay(elt);
			
			// Helper classes
			$binElt.attr('data-slug', slug);
			if($elt.hasClass('is-closing-block'))
				$binElt.addClass('is-closing-block');
			
			// On delete page block
			binElt.addEventListener('removeFromCollection', function(){
				// Remove closing element if exists
				const arr = inst.getChildrenAsArray($bin.get(0), 'css');
				const start = inst.getEltPosition(elt) +1;
				for (let i = start; i < arr.length; i++) {
					let $arrElt = $(arr[i]);
					if(slug === $arrElt.data('slug') && $arrElt.hasClass('is-closing-block')){
						$arrElt.remove();
						return;
					}
				}
			});
		},
		
		getBlockBinElt: function(elt) {
			let $binElt = $(elt).parentsUntil(this.$pageBlocksBin).last();
			if(!$binElt.length)
				$binElt = $(elt);
			return $binElt;
		},
		
		getBlockSlug: function(elt){
			return $(elt).find('[name$="[templateSlug]"]').val();
		},
		
		getBlockContent: function(elt) {
			try{
				return JSON.parse(
					$(elt).find('[name$="[content]"]').val()
				);
			} catch (e) {
				console.error("PageBlock error on element "+this.getBlockSlug(elt)+":");
				console.error(e);
				return {
					text: "JSON Error",
					error: "JSON Error",
				};
			}
		},
		
		setBlockContent: function(elt, content) {
			$(elt).find('[name$="[content]"]').val(
				JSON.stringify(content)
			);
		},
		
		// Ouvrir les accordéons contenant des champs manquants
		// Retourne TRUE si des champs sont manquants
		openMissingFieldContainer: function() {
			
			let hasMissingFields = false;
			inst.$form.find('input').each(function(){
				if($(this).prop('required') && !$(this).val()){
					hasMissingFields = true;
					let $collapsed = $(this).parents('.accordion-collapse.collapse:not(.show)').first();
					if($collapsed.length) {
						$collapsed.prev().find('button').click();
					}
				}
			})
			return hasMissingFields;
		},
		
		// WYSIWYG editor
		initRte: function(block, rteWrapperElt, html, changeCallback, options = {}){
			const rteElt = $('<textarea></textarea>')
				.css({minHeight: '120px'})
				.html(html)
				.val(html)
				.appendTo(rteWrapperElt)
				.get(0)
			;
			
			const config = {...{
				// Custom
				layout: null,
				
				// TinyMce
				target: rteElt,
				plugins: 'code link lists paste autoresize nonbreaking fontawesomepicker',
				paste_as_text: true,
				content_css : "/build/public.css",
				content_style: 'body { margin: 5px 12px; background: #eaeaea; }',
				fontawesomeUrl: 'https://www.unpkg.com/@fortawesome/fontawesome-free@5.14.0/css/all.min.css',

				setup: function(editor) {
					
					// Reference in object
					rteElt.editor = editor;

					// On change - also when inited: allows for content update when editor is re-setted
					editor.on('change init', function(e) {
						
						// Callback passé par le block, auquel on passe
						changeCallback(
							block, // le conteneur du block
							editor.editorContainer, // le conteneur du RTE
							editor.getContent() // le contenu HTML
						);
					});
					
					
				},
				toolbar: 'undo redo | styleselect | bold italic | link | aligncenter | bullist | fontawesomepicker',
				// skin: false,
				// content_css: false,
				// content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
			}, ...options };
			
			// Simple Layout
			if('minimal' === config.layout) {
				config.menubar = false;
				config.branding = false;
				config.statusbar = false;
				config.toolbar = 'bold italic | link | code';
			}
			
			tinymce.init(config);
		},
		
		getRteContent: function(rteWrapperElt) {
			const domElt = $(rteWrapperElt).find('textarea').get(0);
			if(!domElt || typeof domElt == "undefined"){
				console.error("Missing dom container:", domElt, 'in', rteWrapperElt);
				return null;
			}
			return domElt.editor.getContent();
		},
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.pageForm.init();
	});
	
	return module;
};
