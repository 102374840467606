
module.exports = function (APP, $) {
    var module = {};
	
	const inst = {
    	
        init: function (blockElt, scene) {
        	const inst = this;
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
        	const html = data.hasOwnProperty('content')
				? data.content
				: data.error
			;
        	const $tpl = $(scene);
	
			// Offset
			$(`<select class="form-select mb-2" data-name="offset">
					<option value="0">Mise en page : Largeur complète</option>
					<option value="1">Mise en page : Petite marge</option>
					<option value="2">Mise en page : Grande marge</option>
				</select>`)
			.val(config.offset)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="container-fluid mt-2"></div>').parent()
			.appendTo($tpl)
			;
			
			// classes
			$(`<select class="form-select mb-2" data-name="classes">
					<option value="">Style : Normal</option>
					<option value="please-note">Style : Violet sur fond gris</option>
					<option value="apejs-gray smallblock">Style : Notes de pied de page</option>
				</select>`)
			.val(config.classes)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="container-fluid mt-2"></div>').parent()
			.appendTo($tpl)
			;
			
        	// Rte
			const $rteWrapper = $('<div class="block-rte">').appendTo($tpl);
			APP.pageForm.initRte(blockElt, $rteWrapper, html, function(block, editorContainer, htmlContent) {
				inst.onChange(blockElt);
			});
			
        },
	
		getDefaultData: function() {
        	return {
        		content: null,
        		offset: 0,
				classes: null,
			}
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
				content: APP.pageForm.getRteContent($elt.find('.block-rte')),
				offset: $elt.find('[data-name="offset"]').val(),
				classes: $elt.find('[data-name="classes"]').val(),
			} };
		},
	
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
	}
	
	APP.pageForm.addPageBlockMethods('text', inst);
};
