module.exports = function (APP, $, tinymce) {
	
	const inst = APP.rte = {
		
		init: function(){
			inst.initRequiredItems();
		},
		
		initRequiredItems: function(){
			$('textarea.rte:not(.rte-inited)').each(function() {
				inst.initRte(this);
			})
		},
		
		initRte: function(rteElt) {
			const options = $(rteElt).data('options') || {};
			const config = {...{
					// Custom
					layout: null,
					
					// TinyMce
					target: rteElt,
					plugins: 'code link lists paste autoresize',
					paste_as_text: true,
					content_css : "/build/public.css",
					content_style: 'body { margin: 5px 12px; background: #eaeaea; }',
					setup: function(editor) {
						// Reference in object
						// rteElt.editor = editor;
						// On change - also when inited: allows for content update when editor is re-setted
						// editor.on('change init', function(e) {
							// Callback passé par le block, auquel on passe
							// changeCallback(
							// 	block, // le conteneur du block
							// 	editor.editorContainer, // le conteneur du RTE
							// 	editor.getContent() // le contenu HTML
							// );
						// });
					},
					toolbar: 'undo redo | styleselect | bold italic | link | aligncenter | bullist',
					// skin: false,
					// content_css: false,
					// content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
				}, ...options };
			
			// Simple Layout
			if('minimal' === config.layout) {
				config.menubar = false;
				config.branding = false;
				config.statusbar = false;
				config.toolbar = 'bold italic | link | code';
			}
			
			tinymce.init(config);
			$(rteElt).addClass('rte-inited');
		}
		
	};
	
	// Init on load
	$(document).ready(function() {
		APP.rte.init();
	});
	
	return module;
}
