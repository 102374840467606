
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
			
			// Show/hide details
			$(blockElt).find('.if-not-closing').toggle(false === data.isClosing);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					isClosing: $elt.find('[data-name="isClosing"]').prop('checked'),
					slug: $elt.find('[data-name="slug"]').val(),
					title: $elt.find('[data-name="title"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {
				isClosing: false,
				slug: null,
				title: null,
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3">' +
				'<i class="fas fa-info-circle"></i> ' +
				'Ajoutez autant de section que vous voulez, un sous-menu sera généré automatiquement pour permettre une navigation dynamique au sein de la page. ' +
				'Si des contenus doivent se trouver en dessous, et en dehors de cette navigation dynamique, cochez la case ci-dessous pour marquer la fin d\'un ensemble de sections : ' +
				'</p>')
			
			// isClosing
			const $isClosingWrapper = $('<div class="form-check pb-3 text-center">\n' +
				'  <label class="form-check-label">' +
				'    Fin des sections de sous-navigation' +
				'  </label>' +
				'</div>');
			$(`<input type="checkbox" class="form-check-input" data-name="isClosing">`)
				.prop('checked', config.isClosing)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.prependTo($isClosingWrapper.find('label'))
			;
			$tpl.append($isClosingWrapper);
			
			// collapsible
			const $collapsible = $('<div class="if-not-closing pt-3 border-top ">')
			
			// title
			const $titleWrapper = $('<div class="input-group mb-2"><span class="input-group-text" ><i class="fas fa-heading"></i></span></div>');
			$(`<input class="form-control" data-name="title" placeholder="Titre de la section">`)
				.val(config.title)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.appendTo($titleWrapper)
			;
			$collapsible.append($titleWrapper);
			
			// slug
			const $slugWrapper = $('<div class="input-group mb-2"><span class="input-group-text" ><i class="fas fa-hashtag"></i></span></div>');
			$(`<input class="form-control" data-name="slug" placeholder="mon_identifiant_unique">`)
				.val(config.slug)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.appendTo($slugWrapper)
			;
			$collapsible.append($slugWrapper);
			
			$tpl.append($collapsible);
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('collapsible-section', inst);
};
