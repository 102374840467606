
// Container to pass variables from templates
var APP = document.APP = window.APP = {};

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Jquery ui
require('jquery-ui/ui/widgets/sortable');

// Loads the jquery package from node_modules
// $ = global.$ = global.jQuery = require('jquery');
// require("expose-loader?$!jquery");
import $ from "jquery";
jQuery.fn.reverse = function() {
	return this.pushStack(this.get().reverse(), arguments);
};

// Symfony Front Editor
// require('../../vendor/loicpennamen/sf-front-editor/sf-front-editor');

// Autosize textarea
const autosize = require('../../node_modules/autosize/dist/autosize.min');

// Bootstrap
const bootstrap = require('bootstrap');
require('./bootstrap-handler')(APP, bootstrap, $);

//  Datatables
// require( 'datatables.net' )(window, $);
// require( 'datatables.net-bs4' )(window, $);
// require( 'datatables.net-fixedcolumns-bs4' )(window, $);
// require( 'datatables.net-fixedheader-bs4' )(window, $);
// require( 'datatables.net-responsive-bs4' )(window, $);
// require( 'datatables.net-select-bs4' )(window, $);
require('./datatables-handler')(APP, bootstrap, $);

// TinyMce
/* Import TinyMCE */
import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
// import 'tinymce/skins/ui/oxide/skin.css';
// plugins
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/nonbreaking';
import 'tinymce-fontawesomepicker';


// Vendors
import { Fancybox } from "@fancyapps/ui";

// Raw image type
require('../../vendor/loicpennamen/raw-image-type/js/raw-image-type.js')($);

// App
require('./files')(APP, $, Fancybox);
require('./waves')(APP, $);
require('./side-toggler')(APP, $);
require('./sf-collections');
require('./raw-image-upload')(APP, $, bootstrap, Fancybox);
require('./page-form')(APP, $, tinymce, Fancybox);
require('./icon-input')(APP, $);
require('./full-link-type')(APP, $); // todo
require('./partner')(APP, $);
require('./rte')(APP, $, tinymce);

import { SortableCollection } from "./sortable-collection";

// Pageblock : Après standardisation, inclure les versions compilées dans la page via script back-end pour les plugins activés
require('./page-block-text')(APP, $);
require('./page-block-jumbo')(APP, $);
require('./page-block-big-link')(APP, $);
require('./page-block-separator')(APP, $);
require('./page-block-spacer')(APP, $);
require('./page-block-square-nav')(APP, $);
require('./page-block-partners')(APP, $);
require('./page-block-banner')(APP, $);
require('./page-block-contact-form')(APP, $);
require('./page-block-contact-map')(APP, $);
require('./page-block-breadcrumbs')(APP, $);
require('./page-block-anchor')(APP, $);
require('./page-block-columns-html')(APP, $);
require('./page-block-wrapper')(APP, $);
require('./page-block-diptyc')(APP, $);
require('./page-block-service-categories')(APP, $);
require('./page-block-collapsible-section')(APP, $);
require('./page-block-kpi')(APP, $);
require('./page-block-events')(APP, $);


// On page init
$(document).ready(function() {

	// Autosize
	var $autosizeElements = $('textarea');
	autosize($autosizeElements);

	// Debug autosize on hidden TextAreas
	$autosizeElements.on('focus', function(){
		autosize.update($(this));
	});

    // BS : collapse spéciaux
    $('body').on('click', '[data-action="collapseNext"]', function(){
        let elt = $(this).next().get(0);
        elt.collapse = new bootstrap.Collapse(elt)
    });
    
    // Sortable collections
	$('.sortable-collection').each(function(){
		let sc = new SortableCollection(this, {
			// debug: true,
			removeConfirmMessage: "Supprimer cet élément ?",
		});
	});
	
	// Collections: on add item
	document.addEventListener('addToCollection', function(e){
	
		// Update RTE items
		APP.rte.initRequiredItems();
		
		// Update sortable collection
		const wrapper = e.detail.wrapper;
		const newItem = e.detail.newItem;
		if(typeof wrapper.sortableCollection != "undefined")
			wrapper.sortableCollection.addItem(newItem);
		
		// The item may contain embedded sortable collections
		$(newItem).find('.sortable-collection').each(function(){
			new SortableCollection(this, {
				removeConfirmMessage: "Supprimer cet élément ?",
			});
		});
		
		// Resizable TAs
		let $autosizeElements = $(newItem).find('textarea');
		autosize($autosizeElements);
		// Debug hidden
		$autosizeElements.on('focus', function(){
			autosize.update($(this));
		});
		
	});
 
});
