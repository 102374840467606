module.exports = function (APP, bootstrap, $) {
	
	APP.datatablesHandler = {
		
		init: function(){
			const inst = this;
			
			$('table.dataTable').each(function() {
				let $table = $(this);
				
				// Auto-init ?
				if($(this).hasClass('auto-init')){
					$(this).DataTable();
				}
				
				// Placer ce script APRES l'init de DT
				// RéActiver les popover sur les résultats de tableau
				$table.on( 'draw.dt', function () {
					APP.bootstrapHandler.initPopover();
					APP.bootstrapHandler.initTooltip();
				});
			});
			
		},
		
		search: function($table, search) {
			$table.DataTable().search(search).draw();
		},
		
	};
	
	// Init on load
	$(document).ready(function() {
		APP.datatablesHandler.init();
	});
	
	return module;
}
