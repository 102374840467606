const SineWaves = require('sine-waves');

module.exports = function (APP, $) {
	var module = {};
	
	APP.waves = {
		
		init: function () {
			const inst = this;
			$('.waves-container canvas').each(function(){
				inst.initElet(this);
			});
		},
		
		initElet: function(elt) {
			let $parent = $(elt).parent();
			var waves = new SineWaves({
				el: elt,
				speed: .5,
				rotate: 7,
				ease: 'SineInOut',
				wavesWidth: '150%',
				
				// Plus petite à plus grande
				waves: [
					{
						timeModifier: 5,
						lineWidth: 1,
						amplitude: -25,
						wavelength: 25
					},
					{
						timeModifier: 2,
						lineWidth: 2,
						amplitude: -50,
						wavelength: 50
					},
					{
						timeModifier: 1.2,
						lineWidth: 1,
						amplitude: -100,
						wavelength: 100
					},
					{
						timeModifier: 0.6,
						lineWidth: 1,
						amplitude: -250,
						wavelength: 250
					},
					{
						timeModifier: 0.25,
						lineWidth: 2,
						amplitude: -400,
						wavelength: 400
					}
				],
				
				// Called on window resize
				resizeEvent: function() {
					$(elt).css({
						width: $parent.outerWidth() + 'px',
						height: $parent.outerHeight() + 'px'
					});
				}
			});
		}
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.waves.init();
	});
	
	return module;
};
