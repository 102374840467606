// @see https://hacks.mozilla.org/2011/01/how-to-develop-a-html5-image-uploader/

module.exports = function ($) {
	var module = {};
	const inst = document.RawImageType = {
		
		initRequiredWrappers: function () {
			$('.raw-image-type:not(.inited)').each(function() {
				inst.initWrapper(this);
			})
		},
		
		initWrapper: function(wrapper) {
			const $wrapper = $(wrapper);
			$wrapper.$fileInput = $wrapper.find('[name$="[file]"]').first();
			$wrapper.$preview = $wrapper.find('.raw-image-type-preview').first();
			$wrapper.$previewMessage = $wrapper.find('.raw-image-type-preview-message').first();
			$wrapper.$clearBtnWrapper = $wrapper.find('.raw-image-type-clear-wrapper').first();
			$wrapper.$base64Input = $wrapper.find('[name$="[base64]"]').first();
			$wrapper.canvas = $wrapper.find('canvas').get(0);
			$wrapper.maxWidth = parseInt($wrapper.data('maxwidth') || 1920);
			$wrapper.maxHeight = parseInt($wrapper.data('maxheight') || 1080);
			
			// On click preview
			$wrapper.$preview.css('cursor', 'pointer').on('click', function() {
				$wrapper.$fileInput.trigger('click');
			});
			
			// On select image
			$wrapper.$fileInput.on('change', function() {
				let files = $wrapper.$fileInput.get(0).files;
				inst.onSelect($wrapper, files)
			});
			
			// On clear
			$wrapper.on('click', '[data-action="clearRawImage"]', function() {
				inst.clear($wrapper);
			});
			
			// Info
			inst.updatePreview($wrapper);
			
			$wrapper.addClass('inited');
		},
		
		clear: function($wrapper) {
			$wrapper.$fileInput.val(null);
			$wrapper.$base64Input.val(null);
			inst.updatePreview($wrapper);
		},
		
		onSelect: function($wrapper, files) {
			Array.from(files).forEach((file, i) => {
				
				// this file is not an image.
				if (!file.type.match(/image.*/)){
					inst.error("File "+(i+1)+" is not a valid image file: \n"+file.name, true);
					$wrapper.blobCount--;
				}
				
				// Image...
				else{
					
					// Une fois l'image en mémoire vive
					let img = document.createElement("img");
					$(img).data('filename', file.name).on('load', function() {
						let img = this;
						let canvas = $wrapper.canvas;
						
						// Resize
						let width = img.width;
						let height = img.height;
						if (width > height) {
							if (width > $wrapper.maxWidth) {
								height *= $wrapper.maxWidth / width;
								width = $wrapper.maxWidth;
							}
						} else {
							if (height > $wrapper.maxHeight) {
								width *= $wrapper.maxHeight / height;
								height = $wrapper.maxHeight;
							}
						}
						canvas.width = width;
						canvas.height = height;
						
						// Draw
						let ctx = canvas.getContext("2d");
						ctx.drawImage(img, 0, 0, width, height);
						
						// Resized image to blob
						canvas.toBlob(function(blobImage) {
							var reader = new FileReader();
							reader.readAsDataURL(blobImage);
							reader.onloadend = function() {
								var base64data = reader.result;
								
								// Text input
								$wrapper.$base64Input.val(base64data);
								
								// Info
								inst.updatePreview($wrapper);
							}
							
						}, file.type, 0.8);
					});
					
					// Charger l'image
					img.src = window.URL.createObjectURL(file);
				}
			});
			
		}, // Onselect
		
		// Something wrong
		error: function(message, doAlert){
			if("undefined" !== typeof doAlert)
				alert(message);
			console.error("Upload error: ", message);
		},
		
		updatePreview: function($wrapper) {
			if($wrapper.$base64Input.val()){
				$wrapper.$previewMessage.html('');
				$wrapper.$preview.css('background-image', 'url(' + $wrapper.$base64Input.val() + ')');
				$wrapper.$clearBtnWrapper.css('display', 'block');
			}
			else{
				$wrapper.$previewMessage.html(
					$wrapper.$previewMessage.data('empty-message')
				);
				$wrapper.$preview.css('background-image', 'none');
				$wrapper.$clearBtnWrapper.css('display', 'none');
			}
		}
	};
	
	
	// Init on load
	$(document).ready(function() {
		inst.initRequiredWrappers();
	});
	
	return module;
};
