
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
    	defaultColumns: 6,
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Load existing items
			if(data.hasOwnProperty('items')) {
				data.items.forEach(item => {
					inst.addItem(blockElt, scene, {
						autofocus: false,
						columns: item.columns,
						content: item.content,
					});
				});
			}
			else {
				// Empty item
				inst.addItem(blockElt, scene, {
					autofocus: false,
				});
			}
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			APP.pageForm.setBlockContent(blockElt, inst.getData(blockElt));
		},
		
		getData: function(blockElt) {
			let items = [];
				
			$(blockElt).find('.columns-html-block-item').each(function() {
				items.push({
					columns: $(this).find('[data-name="item-columns"]').val(),
					content: APP.pageForm.getRteContent($(this).find('[data-name="item-content"]')),
				});
			});
			
			return {
				title: $(blockElt).find('[data-name="title"]').val(),
				style: $(blockElt).find('[data-name="style"]').val(),
				items: items,
			};
		},
	
		getDefaultData: function() {
			return {
				title: null,
				style: null,
				items: [],
				// items: [inst.getDefaultItemData()],
			};
		},
	
		getDefaultItemData: function() {
			return {
				// TODO : save last col n
				columns: localStorage.getItem('page-block-columns-n') || inst.defaultColumns,
				content: null,
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			let $tpl = $('<div></div>');
			
			// title
			$(`<input class="form-control" data-name="title" placeholder="Titre (H2 - facultatif))">`)
				.val(config.title)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.wrap($('<div class="container-fluid my-2">')).parent()
				.appendTo($tpl)
			;
			
			// Style
			$(`<select class="form-select mb-2" data-name="style">
					<option value="">Mise en page classique</option>
					<option value="spaced">Mise en page aérée</option>
				</select>`)
			.val(config.style)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="container-fluid mt-2"></div>').parent()
			.appendTo($tpl)
			;
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3 mx-3"><i class="fas fa-info-circle"></i> ' +
				'Les colonnes sont en « responsive design » : ' +
				'Elles s\'organisent automatiquement sur les plus petits écrans pour occuper toute la largeur de ceux-ci.' +
				'</p>')
			
			// Add bin
			$tpl.append($('<div class="items-bin"></div>'));
			
			// Add btn
			let $addBtn = $(APP.pageForm.getAddBtnStr({
					label: "Ajouter un élément"
				})).on('click', function() {
					inst.addItem(blockElt, scene, {
						autofocus: true,
					});
				})
				.wrap($('<div class="container-fluid my-2">')).parent()
				.appendTo($tpl)
			;
			
			return $tpl;
		},
	
		addItem: function(blockElt, scene, options = {}) {
			const config = {...inst.getDefaultItemData(), ...options };
			
			// Create item & child elts
			let $tpl = $('<div class="columns-html-block-item border-top container-fluid py-3">');
			
			// buttons
			let $toolbar = $('<div class="btn-group w-100" role="group">');
			let $upBtn = $('<button data-action="item-up" type="button" class="btn btn-light"><i class="fas fa-arrow-up"></i></button>');
			let $downBtn = $('<button data-action="item-down" type="button" class="btn btn-light"><i class="fas fa-arrow-down"></i></button>');
			let $deleteBtn = $('<button data-action="item-delete" type="button" class="btn btn-light"><i class="fas fa-times"></i></button>');
			
			// columns
			const $columnsInput = $(`<select class="form-select" data-name="item-columns">
					<option value="12">Largeur : Toute la page</option>
					<option value="9">Largeur : 3 quarts de page (9/12)</option>
					<option value="8">Largeur : 2 tiers de page (8/12)</option>
					<option value="6">Largeur : 1 demi-page (6/12)</option>
					<option value="5">Largeur : 5/12 (5/12)</option>
					<option value="4">Largeur : 1 tiers de page (4/12)</option>
					<option value="3">Largeur : 1 quart de page (3/12)</option>
				</select>`)
				.val(config.columns)
				.on('change', function() {
					localStorage.setItem('page-block-columns-n', $(this).val())
					inst.onChange(blockElt);
				})
			;
			
			// Append toolbar
			$toolbar.append($columnsInput);
			$toolbar.append($upBtn);
			$toolbar.append($downBtn);
			$toolbar.append($deleteBtn);
			$tpl.append($toolbar);
			
			// HTML content
			let $content = $(`<div data-name="item-content" class="mt-3">`);
			$tpl.append($content);
			
			$(scene).find('.items-bin').append($tpl);
			
			// Init rte after spawn
			APP.pageForm.initRte(blockElt, $content, config.content, inst.onChange, {});
			
			// Autofocus
			if(config.autofocus)
				$(scene).find('select').last().focus();
			
			
			// Toolbar
			$deleteBtn.on('click', function() {
				if(confirm('Supprimer cette diapo ?')) {
					$tpl.slideUp(200, null, function() {
						$tpl.remove();
						inst.updateDisplay(blockElt);
						inst.onChange(blockElt);
					})
				}
			});
			$upBtn.on('click', function() {
				const $binItem = $upBtn.parentsUntil('.items-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'up')
			});
			$downBtn.on('click', function() {
				const $binItem = $downBtn.parentsUntil('.items-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'down')
			});
			
			// Update disp
			inst.updateDisplay(blockElt);
		},
		
		updateDisplay: function(blockElt) {
			$(blockElt).find('.columns-html-block-item').each(function(i) {
				$(this).find('[data-action="item-up"]').toggleClass('disabled', $(this).is(':first-child'));
				$(this).find('[data-action="item-down"]').toggleClass('disabled', $(this).is(':last-child'));
			});
		},
		
		moveItem: function(blockElt, scene, $binItem, moveTo) {
			let items = inst.getData(blockElt)['items'];
			let $binElts = $binItem.parent().children();
			let oPosition = $binItem.index();
			let newPosition;
			
			// To position number
			switch(moveTo){
				case 'up':		newPosition = oPosition -1; 	break;
				case 'down':	newPosition = oPosition +1; 	break;
				default:		newPosition = moveTo;
			}
			if(newPosition < 0 || newPosition > $binElts.length -1)
				return;
			
			// Change array positions
			inst.arrayMove(items, oPosition, newPosition);
			
			// clear and reset bin
			$binElts.remove();
			items.forEach(item => {
				inst.addItem(blockElt, scene, {
					autofocus: false,
					columns: item.columns,
					content: item.content,
				});
			});
		},
		
		arrayMove: function(arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length;
			}
			while (new_index < 0) {
				new_index += arr.length;
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing purposes
		},
	
	};
    
    APP.pageForm.addPageBlockMethods('columns-html', inst);
};
