
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					bg: $elt.find('[data-name="bg"]').val(),
					title: $elt.find('[data-name="title"]').val(),
					category: $elt.find('[data-name="category"]').val(),
					link: {
						label: $elt.find('[data-name="link-label"]').val(),
						url: $elt.find('[data-name="link-url"]').val(),
					},
				} };
		},
		
		getDefaultData: function() {
			return {
				bg: 'white',
				title: null,
				category: null,
				link: {
					label: null,
					url: null,
				},
				availableCategories: [
					null,
					'Ils nous soutiennent',
					'Nos réseaux',
					'Le coin des Copains',
					'Les structures de formation',
					'Les médias',
				]
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3"><i class="fas fa-info-circle"></i> Pour ajouter ou modifier des partenaires , rendez-vous sur <a href="/admin/partners">la page dédiée de l\'administration</a>.</p>')
			
			// title
			$(`<input class="form-control mb-2" data-name="title" placeholder="Titre du bloc (H2 - facultatif)">`)
			.val(config.title)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.appendTo($tpl)
			;
			
			// type
			$(`<select class="form-select mb-2" data-name="bg"><option value="white">Fond blanc</option><option value="ivory">Fond ivoire</option></select>`)
			.val(config.bg)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.appendTo($tpl)
			;
			
			// category
			const $categoryInput = $(`<select class="form-select mb-2" data-name="category"></select>`);
			config.availableCategories.forEach(function(tmpCategory){
				let $option = $('<option>')
					.val(tmpCategory)
					.text((tmpCategory ? "Catégorie : "+tmpCategory : "▸ Toutes les catégories"))
				;
				$categoryInput.append($option);
			});
			$categoryInput.val(config.category)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.appendTo($tpl)
			;
			
			// Link
			let $linkLabelCol = $(`<input class="form-control mb-2" data-name="link-label" placeholder="Texte du lien">`)
				.val(config.title)
				.on('change', function() {
					inst.onChange(blockElt)
				}).wrap('<div class="col-md-6">').parent()
			;
			let $linkUrlCol = APP.pageForm.getLinkUrlInput({
					value: config.link.url,
					attributes: {
						'data-name': 'link-url',
						'placeholder': "URL...",
					},
					onChange: function(input) {
						inst.onChange(blockElt);
						setTimeout(function() {
							$(input).focus().select();
						}, 200);
					}
				}).wrap('<div class="col-md-6">').parent()
			;
			let $linkRow = $('<div class="row mt-3"><div class="col-12">Lien en bas de liste (facultatif) :</div></div>')
			$linkRow.append($linkLabelCol);
			$linkRow.append($linkUrlCol);
			$tpl.append($linkRow);
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('partners', inst);
};
