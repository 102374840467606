/**
 * Collection elements must contain a hidden "position" input.
 *
 * Init as such with jQuery:
 * $('.sortable-collection').each(function(){
 *		var sc = new SortableCollection(this, {});
 *	})
 *
 * To move an element up, a button must contain the following attribute:
 * data-sortable-collection="up"
 *
 * To move an element down, a button must contain the following attribute:
 * data-sortable-collection="down"
 *
 * To remove an element, a button must contain the following attribute:
 * data-sortable-collection="remove"
 *
 * When a new child (=sortable item) is added to the collection, trigger the following method to add handling for this item:
 * collectionElement.sortableCollection.addItem(newItem)
 *
 * When a child (=sortable item) is remove from the collection, trigger the following method to reset siblings positions:
 * collectionElement.sortableCollection.onRemoveItem()
 * This is done automatically if using the "remove" button attribute method.
 *
 * [INFO] Back-end: On page load, sorting is applied in order of "position" inputs values.
 * If position inputs are null or zero, sorting is applied according to DOM position.
 *
 */
export class SortableCollection {
	config = {};
	wrapper = null;
	
	constructor(wrapper, options = {}){
		const inst = this;
		const $wrapper = $(wrapper);
		const cfg = inst.config = {...inst.getDefaults(), ...options};
		inst.wrapper = wrapper;
		
		// Self-reference
		wrapper.sortableCollection = inst;
		
		// Add required styles
		$wrapper.css(inst.config.containerStyles);
		
		// Events
		$wrapper.on('click', cfg.upBtnSelector, function(e) {
			const item = $(this).parentsUntil(inst.wrapper).last().get(0);
			inst.moveItemTo(item, 'up');
			e.stopPropagation();
		});
		$wrapper.on('click', cfg.downBtnSelector, function(e) {
			const item = $(this).parentsUntil(inst.wrapper).last().get(0);
			inst.moveItemTo(item, 'down');
			e.stopPropagation();
		});
		$wrapper.on('click', cfg.removeBtnSelector, function(e) {
			const item = $(this).parentsUntil(inst.wrapper).last().get(0);
			inst.removeItem(item);
			e.stopPropagation();
		});
		
		// Setting positions based on INPUT (or dom) order
		const arr = inst.getChildrenAsArray('input');
		inst.resetItemsPosition(arr);
	}
	
	getDefaults() {
		return {
			positionInputSelector: '[name$="[position]"],[name="position"]',
			upBtnSelector: '[data-sortable-collection="up"]',
			downBtnSelector: '[data-sortable-collection="down"]',
			removeBtnSelector: '[data-sortable-collection="remove"]',
			removeConfirmMessage: null,
			disabledBtnClass: 'disabled',
			debug: false,
			containerStyles: {
				display: 'flex',
				flexDirection: 'column'
			},
		}
	}
	
	addItem(newItem, position = null){
		const inst = this;
		let maxPosition = $(inst.wrapper).children().length -1;
		
		// By default: added at the end
		inst.setItemPosition(newItem, maxPosition);
		
		// clean positions and update visually
		let arr = this.getChildrenAsArray('css');
		inst.resetItemsPosition(arr);
		
		// If inserted "not last"
		if(position)
			inst.moveItemTo(newItem, position);
	}
	
	moveItemTo(item, newPosition) {
		const inst = this;
		
		// Get current sorted array
		let arr = this.getChildrenAsArray('css');
		
		// Current item position
		let position = this.getItemPosition(item);
		
		// Targeted position is another item position
		if('up' === newPosition)
			newPosition = position -1;
		if('down' === newPosition)
			newPosition = position +1;
		
		// Get info about replaced element
		let targetPositionElement = arr[newPosition];
		
		// TODO: allow for preventing elements crossing under certain circumstances
		// Add a "beforeMove" event passing the moved object, and every overpassed object
		// which allow to cancel the move with an "onCancel" callback...
		
		// Move target in array
		let tmpItem = arr.splice(position, 1)[0]; // remove current item and store it
		arr.splice(newPosition, 0, tmpItem); // insert stored item into position `to`
		
		// Apply
		this.resetItemsPosition(arr);
	}
	
	removeItem(item) {
		const inst = this;
		
		if(inst.config.removeConfirmMessage){
			if(!confirm(inst.config.removeConfirmMessage))
				return;
		}
		
		$(item).slideUp("fast", function() {
			$(item).remove();
			
			setTimeout(function(){
				inst.onRemoveItem();
			}, 50);
		});
	}
	
	onRemoveItem() {
		// Reset positions based on visual order
		const arr = this.getChildrenAsArray('css');
		this.resetItemsPosition(arr);
	}
	
	/**
	 * Array of elements ordered by [dom|input|visual] position
	 */
	getChildrenAsArray(positionMethod='dom') {
		const inst = this;
		let arr = [];
		$(inst.wrapper).children().each(function(i) {
			let item = this;
			let position = 0;
			
			// Position of DOM objecy
			if('dom' === positionMethod)
				position = i;
			// Visual position | Position by input
			else
				position = inst.getItemPosition(item, positionMethod);
			
			// Prevent overwriting existing items (ie: when all positions are zero)
			while(position in arr)
				position++;
			
			arr[position] = this;
		});
		
		// Reset array indexes
		const resetArr = arr.filter(function(){return true;});
		return Object.assign([], resetArr); // Debug ffox console
	}

	getItemPosition(item, positionMethod = 'css') {
		const inst = this;
		let positionValue = null;
		
		// Position by input value
		if('input' === positionMethod){
			const $input = $(item).find(inst.config.positionInputSelector);
			if($input.length > 0)
				positionValue = $input.first().val();
		}
		
		// Visual position
		if('css' === positionMethod)
			positionValue = $(item).css('order');
		
		return parseInt(positionValue) || 0;
	}
	
	setItemPosition(item, position) {
		const inst = this;
		
		// Arbitratry position
		$(item).css('order', position);
		
		// Input position
		let $positionInput = $(item).find(inst.config.positionInputSelector);
		if($positionInput.length > 0)
			$positionInput.val(position);
		
		// Debug: show inputs
		if(inst.config.debug)
			$positionInput.attr('type', 'text');
	}
	
	resetItemsPosition(arr) {
		const inst = this;
		for (let i = 0; i < arr.length; i++) {
			let item = arr[i];
			this.setItemPosition(item, i);
			
			// Visuals
			inst.toggleUpBtn(item, 0 !== i);
			inst.toggleDownBtn(item, i < arr.length -1);
		}
	}
	
	toggleUpBtn(item, bool) {
		const inst = this;
		$(item).find(inst.config.upBtnSelector).toggleClass(inst.config.disabledBtnClass, !bool);
	}
	
	toggleDownBtn(item, bool) {
		const inst = this;
		$(item).find(inst.config.downBtnSelector).toggleClass(inst.config.disabledBtnClass, !bool);
	}
	
}
