
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			// const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			// inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			// const data = inst.getData(blockElt);
			// APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			// const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					// style: $elt.find('[data-name="style"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			// const data = APP.pageForm.getBlockContent(blockElt);
			// const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3 mx-3"><i class="fas fa-info-circle"></i> ' +
				'Ce bloc affiche la liste des événements et actualités en fonction de la date du jour et des tags passés en paramètre.' +
				'</p>')
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('events', inst);
};
