
module.exports = function (APP, $) {
	var module = {};
	
	const inst = {
		
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
		
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					slugs: $elt.find('[data-name="slugs"]').val(),
				} };
		},
		
		getDefaultData: function() {
			return {
				slugs: '',
			};
		},
		
		getTemplate: function(blockElt, scene) {
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// Template
			let $tpl = $('<div class="container-fluid my-2"></div>');
			
			// Infos
			$tpl.append('<p class="text-info text-center my-3 mx-3"><i class="fas fa-info-circle"></i> ' +
				'Pour modifier les services en détail, rendez-vous sur la page dédiée : ' +
				'<a href="/admin/services">Gestion des services</a><br>' +
				'Entrez ci-dessous les ancres des catégories à afficher dans le menu.' +
				'</p>')
			
			// slugs
			$(`<textarea data-name="slugs" class="form-control mb-2" rows="1"></textarea>`)
			.val(config.slugs)
			.attr('placeholder', "Ex : ancre_1, ancre_2, ancre_3... ")
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.appendTo($tpl)
			;
			
			return $tpl;
		},
	};
	
	APP.pageForm.addPageBlockMethods('service-categories', inst);
};
