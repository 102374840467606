module.exports = function (APP, $) {
	var module = {};
	
	APP.sideToggler = {
		
		init: function () {

            // Toggle menu
            $('body').on('click', '[data-action=toggleSide]', function(e){
                $(e.delegateTarget).toggleClass('openSide');
                $(e.delegateTarget).toggleClass('disableSide', false);
                return false;
            })

            // Masquer et désactiver le menu
            .on('click', '[data-action=disableSide]', function(e){
                $(e.delegateTarget).toggleClass('openSide', false);
                $(e.delegateTarget).toggleClass('disableSide', true);
                return false;
            })

            // Rétablir le menu
            .on('click', '[data-action=enableSide]', function(e){
                $(e.delegateTarget).toggleClass('openSide', false);
                $(e.delegateTarget).toggleClass('disableSide', false);
                return false;
            })

            ;

		},
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.sideToggler.init();
	});
	
	return module;
};
