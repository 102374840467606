module.exports = function (APP, bootstrap, $) {
	var module = {};
	
	APP.bootstrapHandler = {
		
		init: function () {
			this.initTooltip();
			this.initPopover();
		},
		
		initPopover: function () {
			$('[data-bs-toggle="popover"]:not(.popover-inited)').each(function () {
                this.popover = new bootstrap.Popover(this, {
					container: 'body',
				});
				$(this).addClass('popover-inited');
			});
		},
		
		initTooltip: function () {
			$('[data-bs-toggle="tooltip"]:not(.tooltip-inited)').each(function () {
				this.tooltip = new bootstrap.Tooltip(this, {
					container: 'body',
				});
				$(this).addClass('tooltip-inited');
			});
		},

        closeAll: function() {
            $('.tooltip, .popover').fadeOut({
                complete: function(){
                    $(this).remove();
                }
            });
        }
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.bootstrapHandler.init();
	});
	
	return module;
};
