
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
	
		init: function (blockElt, scene) {
			const data = {...inst.getDefaultData(), ...APP.pageForm.getBlockContent(blockElt)};
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene, data));
			
			// Load existing links
			if(data.hasOwnProperty('links')) {
				data.links.forEach(link => {
					inst.addLink(blockElt, scene, {
						autofocus: false,
						label: link.label,
						url: link.url,
						color: link.color,
						image: link.image,
					});
				});
			}
			// No links
			else {
				inst.addLink(blockElt, scene, {autofocus: false});
			}
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			const data = inst.getData(blockElt);
			APP.pageForm.setBlockContent(blockElt, data);
		},
		
		getDefaultData: function() {
			return {
				title: null,
				links: [],
			}
		},
		
		getDefaultLinkData: function() {
			return {
				autofocus: false,
				label: null,
				url: null,
				color: 'orange',
				image: null,
			};
		},
		
		getData: function(blockElt) {
			let links = [];
			$(blockElt).find('.square-nav-block-link').each(function() {
				links.push({...inst.getDefaultLinkData(), ...{
					label: $(this).find('[data-name="link-label"]').val(),
					url: $(this).find('[data-name="link-url"]').val(),
					color: $(this).find('[data-name="link-color"]').val(),
					image: $(this).find('.raw-image-upload-widget').get(0).rawImageUpload.src,
				}});
			});
			return {
				title: $(blockElt).find('[data-name="title"]').val(),
				links: links,
			};
		},
		
		getTemplate: function(blockElt, scene, data) {
			let $tpl = $('<div></div>');
			
			// Heading
			$(`<input data-name="title" class="form-control">`)
				.attr('placeholder', "Titre (facultatif)...")
				.val(data.title)
				.on('change', function() {
					inst.onChange(blockElt)
				})
				.wrap('<div class="my-3 mx-3">').parent().appendTo($tpl)
			;
			
			// Add bin
			$tpl.append($('<div class="links-bin"></div>'));
			
			// "Add" btn
			$(APP.pageForm.getAddBtnStr({
					label: "Ajouter un lien"
				})).on('click', function() {
					inst.addLink(blockElt, scene);
				})
				.appendTo($tpl)
			;
			
			return $tpl;
		},
	
		addLink: function(blockElt, scene, options) {
			const config = {...inst.getDefaultLinkData(), ...options };
			
			// Create link & child elts
			let $tpl = $('<div class="square-nav-block-link border-top container-fluid py-3">');
			
			// buttons
			let $toolbar = $('<div class="btn-group w-100 mb-2" role="group">');
			let $upBtn = $('<button data-action="link-up" type="button" class="btn btn-light"><i class="fas fa-arrow-up"></i></button>');
			let $downBtn = $('<button data-action="link-down" type="button" class="btn btn-light"><i class="fas fa-arrow-down"></i></button>');
			let $deleteBtn = $('<button data-action="link-delete" type="button" class="btn btn-light"><i class="fas fa-times"></i></button>');
			
			// inputs
			let $labelInput = $(`<input data-name="link-label" class="form-control me-2">`)
				.attr('placeholder', "Intitulé du lien...")
				.val(config.label)
				.on('change', function() {
					inst.onChange(blockElt)
				})
			;
			let $urlInput = APP.pageForm.getLinkUrlInput({
				value: config.url,
				attributes: {
					'data-name': 'link-url',
					'placeholder': "URL...",
				},
				onChange: function(input) {
					inst.onChange(blockElt);
					// setTimeout(function() {
					// 	$(input).focus().select();
					// }, 200);
				}
			});
			let $colorInput = $(`<select data-name="link-color" class="form-select mb-2">
					<option value="orange">Style : Orange</option>
					<option value="purple">Style : Violet</option>
					<option value="lightblue">Style : Bleu clair</option>
					<option value="darkblue">Style : Bleu foncé</option>
				</select>`)
				.val(config.color)
				.on('change', function() {
					inst.onChange(blockElt)
				})
			;
			let $color = $(`<div data-name="link-color" class="mt-3">`);
			let $imageInput = APP.rawImageUpload.getWidget({
				src: config.image,
				fileVersion: 'lg',
				label: 'Image de fond',
				onChange: function() {
					inst.onChange(blockElt)
				}
			});
			
			// Append
			$toolbar.append($labelInput);
			$toolbar.append($upBtn);
			$toolbar.append($downBtn);
			$toolbar.append($deleteBtn);
			$tpl.append($toolbar);
			$tpl.append($urlInput);
			$tpl.append($colorInput);
			$tpl.append($imageInput);
			$tpl.append($color);
			// $(scene).find('.links-bin').prepend($tpl);
			$(scene).find('.links-bin').append($tpl);
			
			// Select when focus on default value
			$labelInput.on('focus', function() {
				if($(this).val() === inst.getDefaultLinkData().label)
					$(this).select();
			});
			
			// Autofocus
			if(config.autofocus)
				$(scene).find('input').first().focus();
			
			// Toolbar
			$deleteBtn.on('click', function() {
				if(confirm('Supprimer ce lien ?')) {
					$tpl.slideUp(200, null, function() {
						$tpl.remove();
						inst.updateDisplay(blockElt);
						inst.onChange(blockElt);
					})
				}
			});
			$upBtn.on('click', function() {
				const $binItem = $upBtn.parentsUntil('.links-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'up')
			});
			$downBtn.on('click', function() {
				const $binItem = $downBtn.parentsUntil('.links-bin').last();
				inst.moveItem(blockElt, scene, $binItem, 'down')
			});
			
			// Update disp
			inst.updateDisplay(blockElt);
		},
		
		updateDisplay: function(blockElt) {
			$(blockElt).find('.square-nav-block-link').each(function(i) {
				$(this).find('[data-action="link-up"]').toggleClass('disabled', $(this).is(':first-child'));
				$(this).find('[data-action="link-down"]').toggleClass('disabled', $(this).is(':last-child'));
			});
		},
		
		moveItem: function(blockElt, scene, $binItem, moveTo) {
			let links = inst.getData(blockElt)['links'];
			let $binElts = $binItem.parent().children();
			let oPosition = $binItem.index();
			let newPosition;
			
			// To position number
			switch(moveTo){
				case 'up':		newPosition = oPosition -1; 	break;
				case 'down':	newPosition = oPosition +1; 	break;
				default:		newPosition = moveTo;
			}
			if(newPosition < 0 || newPosition > $binElts.length -1)
				return;
			
			// Change array positions
			inst.arrayMove(links, oPosition, newPosition);
			
			// clear and reset bin
			$binElts.remove();
			links.forEach(link => {
				inst.addLink(blockElt, scene, {
					autofocus: false,
					label: link.label,
					url: link.url,
					color: link.color,
					image: link.image,
				});
			});
		},
		
		arrayMove: function(arr, old_index, new_index) {
			while (old_index < 0) {
				old_index += arr.length;
			}
			while (new_index < 0) {
				new_index += arr.length;
			}
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing purposes
		},
	
};
    
    APP.pageForm.addPageBlockMethods('square-nav', inst);
};
