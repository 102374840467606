module.exports = function (APP, $) {
	
	const inst = APP.partner = {
		
		init: function(){
			$('[data-route="app_partner_search"] .list-group').each(function() {
				$(this).sortable({
					handle: '.draggable-handle',
					containment: 'parent',
					update: function(event, ui){
						inst.updatePositions(event.target);
					}
				});
			})
		},
		
		updatePositions: function(container) {
			let data = [];
			$(container).children().each(function(i){
				data.push({
					id: $(this).data('id'),
					position: i,
				});
			});
			$.ajax({
				url: $(container).data('sort-url'),
				data: {items: data},
				success: function(result){
					// Back-end error
					if(typeof result.files === "undefined")
						console.error('Ajax result:', result);
					// Success
					else{
						// none
					}
				},
				error: function(result) {
					console.error('Ajax result:', result);
				},
			});
		}
		
	};
	
	// Init on load
	$(document).ready(function() {
		APP.partner.init();
	});
	
	return module;
}
