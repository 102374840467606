
module.exports = function (APP, $) {
    var module = {};
	
    const inst = {
    	defaultColumns: 6,
	
		init: function (blockElt, scene) {
			const data = APP.pageForm.getBlockContent(blockElt);
			
			// Setup scene
			$(scene).append(inst.getTemplate(blockElt, scene));
			
			// Init rte after spawn
			APP.pageForm.initRte(blockElt, $(blockElt).find('.block-rte').get(0), data.content, function(block, editorContainer, htmlContent) {
				inst.onChange(blockElt);
			});
			
			// Save Default data on init
			inst.onChange(blockElt);
		},
	
		onChange: function(blockElt) {
			APP.pageForm.setBlockContent(blockElt, inst.getData(blockElt));
		},
		
		getData: function(blockElt) {
			const $elt = $(blockElt);
			return {...inst.getDefaultData(), ...{
					content: APP.pageForm.getRteContent($elt.find('.block-rte')),
					image: $elt.find('.raw-image-upload-widget').get(0).rawImageUpload.src,
					align: $elt.find('[data-name="align"]').val(),
					style: $elt.find('[data-name="style"]').val(),
				} };
		},
	
		getDefaultData: function() {
			return {
				content: null,
				image: null,
				align: 'right',
				style: null,
			};
		},
	
		getTemplate: function(blockElt, scene) {
			let $tpl = $('<div class="container-fluid mb-2"></div>');
			
			// Default and existing data
			const data = APP.pageForm.getBlockContent(blockElt);
			const config = {...inst.getDefaultData(), ...data };
			
			// image
			APP.rawImageUpload.getWidget({
				src: config.image,
				fileVersion: 'md',
				label: 'Image',
				onChange: function() {
					inst.onChange(blockElt)
				}
			})
			.appendTo($tpl)
			;
			
			let $row = $('<div class="row">');
			
			// Offset
			$(`<select class="form-select mb-2" data-name="align">
					<option value="right">Image à droite</option>
					<option value="left">Image à gauche</option>
				</select>`)
			.val(config.align)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
			
			// Style
			$(`<select class="form-select mb-2" data-name="style">
					<option value="">Style par défaut</option>
					<option value="small">Style : plus petit</option>
				</select>`)
			.val(config.style)
			.on('change', function() {
				inst.onChange(blockElt)
			})
			.wrap('<div class="col-md-6">').parent()
			.appendTo($row)
			;
			
			$row.appendTo($tpl);
			
			// Rte
			const $rteWrapper = $('<div class="block-rte">').appendTo($tpl);
			
			return $tpl;
		},
	
};
    
    APP.pageForm.addPageBlockMethods('diptyc', inst);
};
